<template>
<header id="header" class="app-header navbar" role="menu">
  <div class="main_wrap" :style="{'background-image' : bannerImage }">

    <!-- not working
    <component v-bind:is="modalMenu" ref="modalMenu" @onAction="refreshTimer"></component>
    -->

    <div class="footer_img" v-bind:class="{'bus' : !isEmpty(schoolInfo) && schoolInfo.type==3}">
        <div class="wrapper">
          <!-- for school -->
          <!-- 공용으로 사용하기 위해 학교 깃발이미지를 그리지 않는다. 220705 -->
          <div class="img_wrap" v-if="false && !isEmpty(schoolInfo) && schoolInfo.type < 3">
            <div class="flag">
              <svg version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="70px" height="20px" viewBox="0 0 114 200">
                <g id="layer2" style="display:inline">
                  <g id="layer1">
                    <path
                      d="m 63.336312,71.691314 c 0.657874,0.516957 1.313808,1.019644 1.967858,1.50837 0.703219,0.525466 1.40426,1.034792 2.103196,1.528361 26.43933,18.670713 49.865554,14.793317 74.144764,9.059598 29.00007,-6.848587 59.21709,-16.345568 97.23924,6.769806 -45.13915,-9.123362 -62.64783,29.422791 -93.92824,50.721071 -18.25376,12.42866 -41.19726,18.98383 -77.057915,6.76518 -0.745319,-0.25394 -1.496217,-0.516 -2.252768,-0.78629 -0.821847,-0.29361 -1.650366,-0.59693 -2.485651,-0.9101 z"
                      id="flag"
                      style="fill:#da572b;fill-opacity:1;stroke:none;display:inline">
                    
                      <animate
                        attributeName="d"
                        attributeType="auto"
                        dur="1s"
                        begin="0s"
                        calcMode="spline"
                        keyTimes="0;0.45;1"
                        keySplines="0.05 0 0.95 1; 0.05 0 0.95 1"
                        repeatCount="indefinite"
                        values="
                        m 63.336312,71.691314 c 0.657874,0.516957 1.313808,1.019644 1.967858,1.50837 0.703219,0.525466 1.40426,1.034792 2.103196,1.528361 26.43933,18.670713 49.865554,14.793317 74.144764,9.059598 29.00007,-6.848587 59.21709,-16.345568 97.23924,6.769806 -45.13915,-9.123362 -62.64783,29.422791 -93.92824,50.721071 -18.25376,12.42866 -41.19726,18.98383 -77.057915,6.76518 -0.745319,-0.25394 -1.496217,-0.516 -2.252768,-0.78629 -0.821847,-0.29361 -1.650366,-0.59693 -2.485651,-0.9101 z;
                        m 63.336312,71.691314 c 0.659866,-0.117195 1.31518,-0.23111 1.965989,-0.341782 35.868039,-6.099477 58.054249,-2.347781 74.692589,5.207181 28.76555,13.061556 41.01008,50.467247 78.20427,43.150867 1.88702,-0.3712 3.82051,-0.81132 5.80443,-1.32596 -1.25386,1.41625 -2.4928,2.77815 -3.71749,4.08799 -27.3116,29.21061 -55.84873,15.6355 -76.52492,12.17591 -20.55607,-3.43948 -41.51945,-7.24243 -77.781612,10.26588 -0.960107,0.46356 -1.930939,0.94206 -2.912772,1.43591 z;
                        m 63.336312,71.691314 c 27.630309,21.711854 51.837238,18.253668 76.877978,12.410458 28.75963,-6.711002 58.61912,-16.568046 96.02807,4.947797 0.48339,0.278028 0.96805,0.561294 1.45399,0.849874 0.36429,0.216339 0.72929,0.435664 1.09502,0.658006 -0.44532,-0.09001 -0.88796,-0.175375 -1.32795,-0.256166 -0.4257,-0.07817 -0.84893,-0.152052 -1.26972,-0.221707 -42.82316,-7.088795 -60.3299,29.618914 -90.4756,50.610194 -19.17784,13.35405 -43.47084,20.34745 -82.651304,5.65754 z
                        "
                        id="animate2988" />
                    </path>
                    <path
                      d="m 70.090849,244.00901 -3.61268,-178.010239 -6.5029,-0.33241 0.36127,179.187859 z"
                      id="pole"
                      style="fill:#eee;fill-opacity:1;stroke:none;display:inline" />
                  </g>
                </g>
              </svg>
            </div>
              
            <img width=280 src="@/assets/img/bg_school.png">
          </div>

          <!-- for bus station -->
          <div class="img_bus" v-if="false && !isEmpty(schoolInfo) && schoolInfo.type==3"> <!-- 헤더에 이미지가 없는 것이 깔끔하여 뺌 -->
            <img src="@/assets/img/bg_busstation.png">
          </div>


          <div class="trees" v-if="isCMonitorSchool && newCM != null && $common.isMobile()==false">
            <div v-for="(visible , key) in 12"  :key="key">
              <svg v-bind:id="'svg'+key" v-bind:class="'svg'+key" viewBox="0 0 512 512">
                <path class="tree" :class="{'on': treeVisible[key][0]==true}" d=" M 274.84 371.94 C 286.92 347.78 298.93 323.59 311.00 299.43 C 310.69 330.71 311.90 362.01 314.83 393.15 C 317.13 416.52 320.33 439.89 326.19 462.66 C 330.66 479.23 336.13 496.11 346.83 509.81 C 301.06 509.84 255.29 509.96 209.53 510.00 C 223.59 495.41 233.56 477.20 240.00 458.08 C 249.27 430.45 250.21 400.48 244.99 371.94 C 254.94 371.93 264.89 371.93 274.84 371.94 Z" />
                <path class="leaf-1" :class="{'on': treeVisible[key][1]==true}" d=" M 109.56 163.09 C 131.04 167.41 152.32 173.10 172.71 181.20 C 189.52 188.01 205.87 196.48 219.89 208.10 C 230.38 216.95 239.99 227.82 243.84 241.28 C 249.55 255.14 252.34 270.03 253.53 284.93 C 254.72 300.68 254.36 316.56 252.35 332.23 C 235.58 326.20 221.44 314.50 209.62 301.43 C 195.35 281.73 185.51 259.21 176.99 236.54 C 179.36 256.17 183.73 275.68 190.91 294.13 C 193.75 300.87 196.73 307.80 201.88 313.12 C 213.08 325.10 227.26 333.90 242.00 340.86 C 221.92 345.66 200.37 343.38 181.58 334.92 C 177.78 333.34 174.26 331.18 170.68 329.17 C 165.09 326.02 160.61 321.39 156.29 316.72 C 146.18 303.07 140.91 286.55 138.26 269.93 C 128.76 234.30 119.12 198.71 109.56 163.09 Z" />
                <path class="leaf-2" :class="{'on': treeVisible[key][2]==true}" d=" M 342.78 125.77 C 367.53 113.82 394.22 106.41 421.08 100.96 C 411.44 137.00 401.66 173.00 392.06 209.05 C 389.80 222.78 385.76 236.43 378.41 248.34 C 373.44 256.48 366.59 263.65 358.06 268.08 C 337.43 280.33 312.04 284.30 288.69 278.73 C 303.41 271.70 317.63 262.94 328.82 250.93 C 333.82 245.78 336.72 239.10 339.50 232.60 C 347.04 213.75 351.15 193.71 353.93 173.66 C 345.41 196.54 335.49 219.24 321.16 239.13 C 309.32 252.29 295.12 264.05 278.28 270.09 C 275.38 245.85 275.56 220.99 281.06 197.12 C 282.81 189.03 286.03 181.40 288.76 173.62 C 291.97 165.83 297.24 159.07 303.06 153.06 C 314.41 141.52 328.34 132.89 342.78 125.77 Z" />
                <path class="leaf-3" :class="{'on': treeVisible[key][3]==true}" d=" M 73.24 279.89 C 94.02 272.77 116.16 267.03 138.26 269.93 C 140.91 286.55 146.18 303.07 156.29 316.72 C 145.78 311.46 136.46 304.20 127.18 297.05 C 135.14 306.94 143.69 316.63 154.24 323.83 C 159.05 327.26 164.98 328.28 170.68 329.17 C 174.26 331.18 177.78 333.34 181.58 334.92 C 171.45 341.45 159.10 344.53 147.09 343.38 C 135.29 341.73 125.77 333.44 118.33 324.66 C 103.38 309.66 88.21 294.87 73.24 279.89 Z" />
                <path class="leaf-4" :class="{'on': treeVisible[key][4]==true}" d=" M 377.93 288.87 C 383.82 284.56 390.46 280.51 398.01 280.53 C 414.90 280.10 430.73 287.54 445.16 295.57 C 433.09 304.04 420.89 312.31 408.85 320.81 C 402.67 325.87 395.32 330.56 387.04 330.47 C 376.26 329.74 366.08 323.74 359.94 314.87 C 368.26 316.92 377.69 318.91 385.70 314.78 C 393.27 311.04 399.97 305.77 406.28 300.21 C 397.95 304.63 389.43 309.00 380.21 311.22 C 373.20 312.32 365.82 311.96 359.12 309.49 C 364.34 301.76 370.57 294.64 377.93 288.87 Z" />
                <path class="leaf-5" :class="{'on': treeVisible[key][5]==true}" d=" M 421.43 217.40 C 425.19 213.50 430.66 211.91 435.88 211.19 C 444.61 210.18 453.46 211.18 461.99 213.10 C 455.73 221.10 449.36 229.02 443.16 237.07 C 440.05 241.61 436.25 246.26 430.76 247.87 C 423.59 249.56 415.86 247.52 410.18 242.91 C 415.06 242.49 420.24 242.12 424.52 239.49 C 429.78 235.57 433.59 230.07 437.08 224.59 C 432.63 228.72 428.28 233.05 423.05 236.19 C 418.67 238.54 413.67 239.65 408.72 239.50 C 411.43 231.42 415.28 223.45 421.43 217.40 Z" />
                <path class="leaf-6" :class="{'on': treeVisible[key][6]==true}" d=" M 49.98 199.95 C 62.18 197.57 75.92 195.50 87.33 201.71 C 95.48 207.57 99.98 217.11 103.20 226.34 C 98.04 226.49 92.81 225.28 88.33 222.69 C 83.23 219.39 78.83 215.15 74.49 210.94 C 78.03 216.43 81.75 222.02 86.96 226.08 C 91.29 228.97 96.69 229.40 101.74 229.76 C 96.04 234.38 88.25 236.45 81.07 234.67 C 75.70 233.04 71.95 228.51 68.90 224.05 C 62.68 215.95 56.25 208.01 49.98 199.95 Z" />
                <path class="leaf-7" :class="{'on': treeVisible[key][7]==true}" d=" M 163.04 37.09 C 185.85 44.90 208.39 54.58 227.68 69.27 C 238.21 77.46 248.09 87.34 253.22 99.88 C 261.44 124.23 259.73 150.75 253.79 175.41 C 241.83 169.27 232.30 159.20 224.73 148.26 C 215.95 132.32 210.67 114.72 206.31 97.13 C 206.80 114.13 208.17 131.39 213.69 147.59 C 215.51 153.40 219.27 158.30 223.31 162.73 C 229.82 169.74 237.41 175.66 245.42 180.84 C 229.41 182.42 212.96 178.29 199.54 169.45 C 194.65 166.10 189.75 162.52 186.36 157.56 C 178.43 146.38 175.84 132.43 175.09 118.99 C 171.12 91.68 167.05 64.39 163.04 37.09 Z" />
                <path class="leaf-8" :class="{'on': treeVisible[key][8]==true}" d=" M 301.16 45.20 C 317.65 33.17 337.69 27.34 357.44 23.27 C 352.38 42.50 347.08 61.67 342.04 80.89 C 340.14 91.56 336.57 102.82 328.02 110.05 C 316.34 118.78 300.78 121.95 286.58 118.70 C 295.43 114.05 304.61 108.87 310.47 100.51 C 316.93 88.96 319.43 75.71 321.41 62.77 C 316.71 74.68 311.72 86.66 304.19 97.09 C 297.90 104.35 290.11 110.60 281.06 114.03 C 278.95 97.32 279.60 79.78 285.95 64.00 C 288.64 56.19 294.69 50.08 301.16 45.20 Z" />
                <path class="leaf-9" :class="{'on': treeVisible[key][9]==true}" d=" M 94.21 47.43 C 105.91 51.41 117.43 56.31 127.71 63.25 C 135.08 68.41 142.20 74.69 145.89 83.08 C 150.84 97.21 149.81 112.68 146.42 127.04 C 139.54 123.46 133.99 117.71 129.66 111.36 C 124.63 102.35 121.78 92.34 119.08 82.43 C 119.55 92.72 120.26 103.27 124.14 112.92 C 127.91 120.33 134.83 125.46 141.51 130.09 C 130.01 131.28 118.11 127.19 109.73 119.25 C 103.31 112.55 101.66 102.92 101.02 94.02 C 98.81 78.48 96.45 62.97 94.21 47.43 Z" />
                <path class="leaf-10" :class="{'on': treeVisible[key][10]==true}" d=" M 374.05 59.04 C 377.59 55.75 382.45 54.40 387.11 53.66 C 396.03 52.39 405.08 53.63 413.83 55.42 C 407.64 63.36 401.32 71.20 395.18 79.18 C 392.08 83.70 388.38 88.34 382.96 90.08 C 375.73 92.05 367.86 89.86 362.08 85.27 C 367.50 84.78 373.50 84.43 377.86 80.78 C 382.46 76.74 385.99 71.67 389.25 66.53 C 384.67 70.90 380.12 75.43 374.63 78.65 C 370.35 80.89 365.48 81.97 360.65 81.85 C 363.38 73.42 367.51 65.16 374.05 59.04 Z" />
                <path class="leaf-11" :class="{'on': treeVisible[key][11]==true}" d=" M 240.12 34.99 C 241.08 22.04 249.66 11.35 257.93 1.99 C 261.59 11.36 265.11 20.78 268.82 30.12 C 271.22 35.32 273.46 41.17 271.93 46.94 C 269.70 54.04 263.92 59.74 257.02 62.37 C 259.40 57.62 261.93 52.49 261.42 47.03 C 260.48 40.78 257.77 34.98 255.00 29.37 C 256.24 35.53 257.87 41.70 257.81 48.04 C 257.54 52.95 255.94 57.78 253.31 61.93 C 246.80 54.31 240.70 45.28 240.12 34.99 Z" />
              </svg>
            </div>
          </div>


          <div class="birds" v-if="isCMonitorSchool && newCM != null">
            <div v-for="(visible , key) in 12"  :key="key">
            <div v-bind:id="'bird'+key" class="bird-container" v-bind:class="'bird-container--'+key" v-if="birdVisible[key]">
              <div class="bird" v-bind:class="'bird--'+key"></div>
            </div>
            </div>
          </div>

          <!--
          <div class="bird-container bird-container--two">
            <div class="bird bird--two"></div>
          </div>

          <div class="bird-container bird-container--three">
            <div class="bird bird--three"></div>
          </div>
          -->
          

        </div> 

          <div id="clouds" v-if="false">      
            <div class="cloud x1"></div>      
            <div class="cloud x2"></div>      
            <div class="cloud x3"></div>      
            <div class="cloud x4"></div>      
            <div class="cloud x5"></div>    
          </div>

      </div>



      <div class="header">      
      <div class="sitename_logout_wrapper w-100 flex_set">
      <div class="menu_area flex_start_center" v-if="false">
        <a slot="start" @click="openMenu" v-if="false">
          <div class="menu_button">
            <img width="18" src="@/assets/img/ico_menu.png" />
          </div>
        </a>
        <!--<div class="logo"><img width="68" src="@/assets/img/logo_s.png" /></div>-->
        <div id="menu" class="menu" v-if="false && menuOn">
          <a class="item" @click="onMenuRequested(1)" v-if="(isCMonitorSchool && newCM != null && authInfo.authority!=2)">탄소모니터</a>
          <a class="item" @click="onMenuRequested(2)" v-if="(authInfo.authority==0 || (isIotCerti==false && (authInfo.authority==1 || authInfo.authority == 10))) && schoolListLength !== 0">기기제어</a>
          <a class="item" @click="onMenuRequested(3)" v-if="(authInfo.authority < 2 || authInfo.authority == 10) && schoolListLength !== 0">전문통계</a>
          <a class="item" @click="onMenuRequested(4)" v-if="(authInfo.authority < 2 || authInfo.authority == 10) && schoolListLength !== 0">설치관리</a>
          <a class="item" @click="onMenuRequested(5)" v-if="(authInfo.authority==0 || (isIotCerti==false && (authInfo.authority==1 || authInfo.authority == 10))) && schoolListLength !== 0">공지사항</a>
          <a class="item" @click="onMenuRequested(6)">계정관리</a> <!-- v-if="schoolListLength !== 0"-->
          <a class="item" @click="onMenuRequested(7)" v-if="authInfo.authority == 0 || (isIotCerti==false && authInfo.authority == 10)">관리자메뉴</a>
          <a class="item" @click="onLogoutRequested">로그아웃</a>
        </div>
        <div class="platform">{{(schoolListLength !== 0 && schoolInfo) ? schoolInfo.name : ""}}</div>

      <div class="flex_rc main_info_flip" v-if="false && isCMonitorSchool && slideText.length > 0">
        <div class="header_title_filp" v-bind:class="{'bus': !isEmpty(schoolInfo) && schoolInfo.type==3}">
          <VueSlickCarousel v-bind="slickSettings" ref="carousel">
            
            <div v-for="(elm , key) in slideText" :key="key">
              <div class="slide">{{elm.text}}<template v-if="elm.button == true"><button type="button" @click="openGuide" class="btn_realtime_detail">자세히 보기</button></template></div>
            </div>

          </VueSlickCarousel>
        </div>
      </div>

      </div>
      
      <div class="flex_btm is_vertical ctotal" v-if="false && isCMonitorSchool && newCMonitor">
        <!--
        <div>
          <span class="item">탄소배출량</span><span class="value">123,456kgCO2</span>
          <span class="item">2022년달성률</span><span class="value">10%</span>
        </div>-->
        <div v-if="isCMonitorSchool && newCM != null">
          <span class="item">2030탄소절감목표까지 남은시간</span><span class="value">{{newCMRemainTimeStr}}</span>
        </div>
      </div>
      
<!--      <div class="sitename"><img class="sitelogo" :src="'/iot/image/'+schoolInfo.logoFileNm" v-if="schoolInfo.logoFileNm != null"> {{(schoolListLength !== 0 && schoolInfo) ? schoolInfo.name : ""}}</div>-->
      <div class="flex_start_center sitelogoname">
        <div class="flex_cc refresh" @click="refresh" v-if="schoolListLength !== 0 && schoolInfo && !isEmpty(schoolInfo)">
          <img class="sitelogo" :src="'/iot/image/'+schoolInfo.logoFileNm" v-if="schoolInfo.logoFileNm != null">
          <div class="sitename">{{schoolInfo.name}}</div>
          <img class="refresh_icon" src="@/assets/img/ico_btn_update3.png">
        </div>

        <!--
        <a class="siteonoff" @click="onSiteListRequested" v-if="authInfo.authority == 0 || (isIotCerti==false && authInfo.authority == 10)"><img src="@/assets/img/arrow-down.png"></a>
        -->

        <div class="flex_cc userinfo">
          <a class="notice" @click="onPushNoticeListRequested" v-if="(authInfo.authority==0 || authInfo.authority==10) && (pushNoticeCount > 0)"><img src="@/assets/img/noti_error.png"></a>
          <div class="privacy" v-if="isSaas"><router-link to="/private_policy" tag="a">개인정보처리방침</router-link></div>
          <span class="name">{{authInfo.name}}</span>
          <a class="logout" @click="onLogoutRequested"><img src="@/assets/img/log_out.png"></a>
        </div>
      </div>

      <div class="sitelist" v-bind:class="[searchExpand ? 'expand' : noticeExpand ? ((noticeExpandMini) ? 'notice mini' : 'notice') : '']" v-if="(schoolListLength > 0) && (authInfo.authority == 0 || (isIotCerti==false && authInfo.authority == 10))">
        <!--<div class="onoff" @click="onSiteListRequested" v-if="suggestions.length > 0">{{searchExpand ? "사이트목록 숨기기" : "사이트목록 보이기"}}</div>-->
        <a class="flex_cc onoff" @click="onSiteListRequested" v-if="noticeExpand == false">
          <template v-if="searchExpand"><img src="@/assets/img/arrow-up.png"></template>
          <template v-else><img src="@/assets/img/arrow-down.png"></template>
        </a>
        <div class="flex_cc" v-if="noticeExpand == true">
          <a class="flex_cc onoff" @click="onPushNoticeListSizeRequested">
            <template v-if="noticeExpandMini==false"><img src="@/assets/img/arrow-up.png"></template>
            <template v-else><img src="@/assets/img/arrow-down.png"></template>
          </a>
          <a class="flex_cc onoff" @click="onPushNoticeListCloseRequested">
            <img src="@/assets/img/close-w.png">
          </a>
        </div>

        <div class="flex_rc numsites2" v-if="searchExpand == true">{{suggestionNew.length == 0 ? suggestions.length : suggestionNew.length}}개 사이트</div>

        <template v-if="searchExpand == true">
        <div class="flex is_vertical search w-100" v-if="suggestions.length > 0">
          <div class="flex searchFilter">
            <div class="flex" v-for="(filter, key) of suggestionFilter" :key="key">
              <div @click="onSchoolFilterButtonClick(filter)" style="cursor: pointer; padding:2px 10px;">{{filter}}</div>
            </div>
            <input class="searchInput" type="text" size="30" placeholder="사이트 검색" maxlength="30" v-model="suggestionSearchInput" @keyup="searchSchool">
            <div class="numsites" v-if="false">{{suggestionNew.length == 0 ? suggestions.length : suggestionNew.length}}개 사이트</div>
          </div>  

          <!-- scholl search -->
          <div class="schoolList">
            <div class="flex" :class="{'w-100 m-l-md': false && $common.isMobile()==true}" v-for="(suggestion , key) of suggestionNew"  :key="key">
              <div class="sitenameeach" @click="onSchoolSearchButtonClickWithName(suggestion.name, suggestion.schoolUuid)" :class="{'current': schoolInfo.name === suggestion.name && schoolInfo.uuid === suggestion.schoolUuid}">{{suggestion.name}}<span style="padding:0 10px;" v-if="true || $common.isMobile()==false && key !== suggestionNew.length-1"></span></div>
            </div>
          </div>

        <!--</div>-->

        <!--</div>-->
        </div>
        </template>

        <template v-if="noticeExpand == true">

          <!-- scholl search -->
          <div class="noticeList"  v-if="suggestions.length > 0">
            
            <div class="flex_rc numsites2" style="--background:#f00;" v-if="authInfo.authority == 0 || authInfo.authority == 10">
              <span class="pointer error" :class="{on:pushNoticeViewMode=='E'}" @click="clickNoticeLevel('E')">E</span>
              <span class="pointer warnning m-l-sm" :class="{on:pushNoticeViewMode=='W'}" @click="clickNoticeLevel('W')">W</span>
              <span class="pointer info m-l-sm" :class="{on:pushNoticeViewMode=='I'}" @click="clickNoticeLevel('I')">I</span>
              <template v-if="authInfo.authority == 0">
                <span class="pointer skip m-l-sm" :class="{on:pushNoticeViewMode=='X'}" @click="clickNoticeLevel('X')" v-if="false">X</span>
                <span class="pointer skip m-l-sm" :class="{on:pushNoticeViewMode=='YP'}" @click="clickNoticeLevel('YP')">YP</span>
              </template>
              <span style="width:100%;" v-if="$common.isMobile()"></span>
              <span class="pointer info m-l-sm" :class="{on:pushNoticeViewMode=='NOTICEALL'}" @click="clickNoticeAll()">ALL</span>
              <template v-if="authInfo.authority == 0">  
                <span class="pointer info m-l-sm" :class="{on:pushNoticeViewMode=='TOTAL'}" @click="clickTotal()">TOTAL</span>
              </template>
            </div>

            <div class="notice_table cursor-d" v-if="false">
              <table style="width: calc(100% - 23px); margin-left:13px; margin-bottom:10px; ">
                <tr class="fixedwordwrap">
                    <th style="width:15%;">디바이스</th>
                    <th style="width:10%;">생성일시</th>
                    <th style="width:10%;">업데이트</th>
                    <th style="width:15%;">디바이스</th>
                    <th style="width:40%;">알림</th>
                    <th style="width:10%;">...</th>
                </tr>
              </table>

              <ul class="tree w-100" v-for="(school, key) of pushNoticeList" :key="key" v-show="school[0].length > 0">
                <li>
                  <details open>
                    
                    <summary>{{school[0][0].schoolName}}</summary>
                    <div class="school_change" @click="onSchoolSearchButtonClickWithName(school[0][0].schoolName, null)" v-if="false">{{school[0][0].schoolName}} (으)로 이동</div>
                            <table class="m-t-xs m-b-xs" style="margin-left:-15px; width:calc(100% + 15px);">
                    <tbody v-for="(cls, key1) of school" :key="key1">
                            <tr class="fixedwordwrap" v-for="(notice, key2) of cls" :key="key2">
                                <td class="text-left" style="width:15%;" :rowspan="cls.length" v-if="key2==0">
                                  <div class="pointer off" @click="onSchoolSearchButtonClickWithName(notice.schoolName, null)">{{notice.fullName}}</div>
                                </td>
                                <td style="width:10%;" v-html="notice.createDateTime"></td>
                                <td style="width:10%;" v-html="notice.updateDateTime"></td>
                                <td class="text-left" style="width:15%;">{{notice.apcModel}}</td>
                                <td class="text-left" style="width:40%;" v-html="notice.noticeTypeStr"></td>
                                <td style="width:10%;"> bbbbbb</td>
                            </tr>
                    </tbody>
                            </table>

                    <ul v-if="false">
                      <li v-for="(cls, key1) of school" :key="key1">
                        <details open>
                          <summary>{{cls[0].fullName}}</summary>
                          <ul>
                            <table class="w-100 m-t-xs m-b-xs">
                            <tr class="fixedwordwrap" v-for="(notice, key2) of cls" :key="key2">
                                <td style="width:15%;" v-html="notice.createDateTime"></td>
                                <td style="width:15%;" v-html="notice.updateDateTime"></td>
                                <td style="text-align:left; width:20%;">{{notice.apcModel}}</td>
                                <td style="text-align:left; width:40%;" v-html="notice.noticeTypeStr"></td>
                                <td style="width:10%;"> bbbbbb</td>
                            </tr>
                            </table>
                          </ul>
                        </details>
                      </li>
                    </ul>
                  </details>
                </li>
              </ul>
              
            </div>

            <div class="notice_table cursor-d" v-if="true">
              <table>
              <thead>
              <tr class="fixedwordwrap">
                   
                <th style="--width:20%; min-width:80px;" v-if="false">
                  <div class="help">
                    <div class="flex_cc icon_text bold">사이트</div>
                    <div class="dropup">
                      <div class="content" :class="[$common.isMobile()?'md left':'lg center']">
                        <div class="desc">클릭하면 해당 사이트로 이동합니다.</div>
                      </div>
                    </div>
                  </div>
                </th>
                <th style="--width:20%;">장소</th>

                <template v-if="$common.isMobile()">
                  <template v-if="authInfo.authority == 0">
                    <th style="--width:10%; min-width:55px;">일시</th>
                  </template>
                </template>
                <template v-else>
                  <th style="--width:10%; min-width:55px;">생성일시</th>
                  <th style="--width:10%; min-width:55px;">업데이트</th>
                </template> 

                <th style="--width:20%;">디바이스</th>
                <th style="--width:25%;">알림<template v-if="pushNoticeCount > 0"> ({{pushNoticeCount}})</template></th>
                <th style="--width:8%;" colspan="3" v-if="authInfo.authority == 0">
                  <div class="help">
                    <div class="flex_cc icon_text bold">..</div>
                    <div class="dropup">
                      <div class="content right" :class="[$common.isMobile()?'md':'lg']">
                        <div class="desc">P: 알림을 숨깁니다.</div>
                        <div class="desc">R: 숨긴알림을 복원합니다.</div>
                        <div class="desc">X: 알림을 삭제합니다.</div>
                      </div>
                    </div>
                  </div>  
                </th>
              </tr>
              </thead>

              <template v-for="(school, key) of pushNoticeList">
              <tr :key="school.schoolName" v-if="school.classList.length > 0 && school.classList[0].length > 0">
                <td class="site" colspan="999">
                  <div class="flex_cc site_expand pointer off" @click="onSchoolNoticeShowHide(school)">
                    <div :class="{current: schoolInfo.name == school.schoolName}" @click.stop="onSchoolSearchButtonClickWithName(school.schoolName, null)">{{school.schoolName}} ({{school.length}})</div>
                    <img :src="school.show ? require('../assets/img/ico_cal_minus.png') : require('../assets/img/ico_cal_plus.png')" />
                  </div>
                </td>
              </tr>
              <tbody v-for="(cls, key1) of school.classList" :key="key+'_'+key1">
              <tr class="fixedwordwrap" v-for="(notice, key2) of cls" :key="key1+'_'+key2" v-show="school.show">
                
                <td class="text-left" :rowspan="cls.length" v-if="key2==0">{{(notice.fullName) ? notice.fullName : notice.gatewayMac}}</td>

                <template v-if="$common.isMobile()">
                  <template v-if="authInfo.authority == 0">
                    <td v-html="notice.updateDateTime ? notice.updateDateTime : notice.createDateTime"></td>
                  </template>
                </template>
                <template v-else>
                  <td v-html="notice.createDateTime"></td>
                  <td v-html="notice.updateDateTime"></td>
                </template>

                <td class="text-left">{{notice.apcModel}}</td>
                <td class="text-left" v-html="notice.noticeTypeStr"></td>
                <template v-if="authInfo.authority == 0">
                  <td :class="{'notice' : notice.noticeYn == 'Y' || notice.noticeYn == 'YP'}">{{notice.noticeYn}}</td>
                  <td style="vertical-align: top;">
                    <div class="pointer" @click="updatePushPending(cls, notice)">
                      <template v-if="notice.noticeYn.length == 2">R</template>
                      <template v-else>P</template>
                    </div>
                  </td>
                  <td :class="{error: notice.error}" style="vertical-align: bottom;"><div class="pointer" @click="deletePushNotice(school, cls, notice)">X</div></td>
                </template>
              </tr>
              </tbody>
              </template>

            </table>
            </div>  
          </div>
        </template>

      </div>

      <div class="flex_cc userinfo" v-if="false">
        <span class="name">{{authInfo.name}}</span>
        <div class="privacy" @click="onMenuRequested(8)" v-if="isSaas">개인정보처리방침</div>
        <span class="logout" @click="onLogoutRequested">로그아웃</span>
      </div>

      <!--
      <div slot="end" class="main_info">
        <a class="flex_set info" @click="openGuide" v-if="true">
          <img width="18" src="@/assets/img/ico_info_color.png" />
          <div id="desc"></div>
        </a>
      </div>
      -->

<!--
      <div class="main_info_flip">
        <div class="header_title_filp">
            <VueSlickCarousel v-bind="slickSettings">
              <div class="slide">{{slideText[0]}}<button type="button" @click="openGuide" class="btn_realtime_detail">자세히 보기</button></div>
              <div class="slide">{{slideText[1]}}<button type="button" @click="openGuide" class="btn_realtime_detail">자세히 보기</button></div>
            </VueSlickCarousel>
        </div>
      </div>
-->

      </div>

      <div class="flex_start_center header_content" v-bind:class="{'expand':true || isCMonitorSchool==false}" style="align-items:flex-start;">
        <!--<div class="flex flex_start_center">-->
        <!--<div class="school_name">{{(schoolInfo && Object.keys(schoolInfo).length !== 0) ? schoolInfo.name : ""}}</div>-->
        <!--<div class="flex flex_start_center" style="background:#f00;width:50%;">-->
        <div class="flex is_vertical" style="width:auto;" v-if="false">
        <div class="school_name" v-bind:class="{'fixed' : authInfo.authority == 0 || authInfo.authority == 10}">{{(schoolListLength !== 0 && schoolInfo) ? schoolInfo.name : ""}}</div>
        
        <div class="nickname m-t-xs">{{authInfo.name}} 님 안녕하세요.</div>
        <div class="privacy m-t-xs" v-if="isSaas">
          <div class="text"><router-link to="/private_policy" tag="a">개인정보처리방침</router-link></div>
        </div>
        </div>

        <!-- 주소 표시 -->
        <div style="margin-left:-6px;">
        <!--
        <div class="school_address">{{(schoolListLength !== 0 && !isEmpty(schoolInfo)) ? schoolInfo.address1 + " " + schoolInfo.address2 : ""}}</div>
        -->
        <div class="school_address">{{(schoolListLength !== 0 && !isEmpty(schoolInfo)) ? schoolInfo.address1 + (schoolInfo.address2 ? ", " + schoolInfo.address2 : "") : ""}}</div>
        </div>        
        
        <div class="right" v-if="false && suggestions.length > 1">
          <div class="search_wrap">
            <!-- 최고 관리자일 경우만 -->
            <div class="input-group " v-if="authInfo.authority == 0 || authInfo.authority == 10">
              <!-- <input
                type="text"
                ng-model="selected"
                typeahead="state for state in states | filter:$viewValue | limitTo:8"
                class="no-border"
                placeholder="돌마초등학교"
              /> -->
              <!-- 자동완성 검색 기능 추가 -->
              <vue-autosuggest
                v-model="suggestion"
                :suggestions="filteredOptions"
                @selected="onSelected"
                :get-suggestion-value="getSuggestionValue"
                @keyup.enter="onSchoolSearchButtonClick"
                :input-props="{id:'autosuggest__input', placeholder:schoolInfo ? schoolInfo.name: '', maxLength:30}">
                <div slot-scope="{suggestion}" style="cursor: pointer; display: flex; align-items: center;">
                  <div style="{ display: 'flex', color: 'navyblue'}">{{suggestion.item.name}}</div>
                </div>
              </vue-autosuggest>
              
              <button type="submit" class="btn" @click="onSchoolSearchButtonClick">
                <img src="../assets/img/ico_search_white.png">
              </button>
              
            </div>
          </div>
          <!--<button class="btn power" @click.prevent="onLogoutRequested"><img src="../assets/img/ico_power.png"></button>-->
        </div>

        

        <!-- school search filter -->
        <template v-if="false && (authInfo.authority == 0 || (isIotCerti==false && authInfo.authority == 10))">
        <div class="showhide" @click="onShowHide()">{{isShowSearch==false ? '+' : '-'}}</div>
        <div class="flex is_vertical search w-100" v-if="suggestions.length > 1">
          <div class="flex searchFilter" v-if="isShowSearch == true || searchExpand == true">
            <div class="flex" v-for="(filter, key) of suggestionFilter" :key="key">
              <div @click="onSchoolFilterButtonClick(filter)" style="cursor: pointer; padding:2px 10px;">{{filter}}</div>
            </div>
            <input class="searchInput" type="text" size="30" placeholder="사이트 검색" maxlength="30" v-model="suggestionSearchInput" @keyup="searchSchool">
            <div class="numsites">{{suggestionNew.length == 0 ? suggestions.length : suggestionNew.length}}개 사이트</div>
          </div>  

          <!-- scholl search -->
          <div class="schoolList">
            <div class="flex" v-for="(suggestion , key) of suggestionNew"  :key="key">
              <div @click="onSchoolSearchButtonClickWithName(suggestion.name, suggestion.schoolUuid)" style="cursor: pointer;">{{suggestion.name}}</div><template v-if="key !== suggestionNew.length-1">ᆞ</template>
            </div>
          </div>

        <!--</div>-->

        <!--</div>-->
        </div>
        </template>

        <div class="ctotal2" v-bind:class="{'w-100':true || (authInfo.authority!=0 && authInfo.authority != 10)}" v-if="isCMonitorSchool && newCMonitor && newCM != null && newCM.sum != null">
          <table class="ctable">
            <tr>
              <td><span class="item">탄소절감목표</span></td>
              <td><span class="value">{{numberWithCommas(newCM.target.saveKgCo2.toFixed(3))}} kgCO2</span></td>
              <td><span class="value"><template v-if="true">{{numberWithCommas(newCM.target.saveRate.toFixed(3))}}%</template></span></td>
            </tr>
            <tr>
              <td><span class="item">탄소절감현황</span></td>
              <td><span class="value" v-bind:class="{'noti':newCM.sum.saveSumKgCo2<0}">{{numberWithCommas(newCM.sum.saveSumKgCo2.toFixed(3))}} kgCO2</span></td>
              <td><span class="value" v-bind:class="{'noti':newCM.sum.saveSumRate<0}">{{numberWithCommas(newCM.sum.saveSumRate.toFixed(3))}}%</span></td>
            </tr>
          </table>

<!--
          <div><span class="item">탄소절감목표</span><span class="value">{{numberWithCommas(newCM.target.saveKgCo2.toFixed(3))}}kgCO2<template v-if="true"> ({{newCM.target.saveRate}}%)</template></span></div>
          <div><span class="item">탄소절감현황</span><span class="value">{{numberWithCommas(newCM.sum.saveSumKgCo2.toFixed(3))}}kgCO2 ({{(newCM.sum.saveSumRate).toFixed(3)}}%)</span></div>
-->          
        </div>

<!--
        <div class="flex" style="width:100%;">
        <div class="nickname m-t-xs">{{authInfo.name}} 님 안녕하세요.</div>
        <div class="privacy m-t-xs" v-if="isSaas">
          <div><router-link to="/private_policy" tag="a">개인정보처리방침</router-link></div>
        </div>
        </div>
-->
      </div>

<!--
      <DeviceCtrl 
              :open="openArr[0]" 
              :type="type"
              @typeChange="typeChange"
              @onCancel="closeCtrlMenu"
              @onSuccess="setCtrlStatus(0, 0)"/>
-->

      <div class="flex footer" :class="{'bus--': !isEmpty(schoolInfo) && schoolInfo.type==3}">
        <div v-if="menuOn">
          <template v-if="false"> <!-- old function -->
            <div id="tabs" class="flex_cc tabs" v-if="!isEmpty(schoolInfo)">
              <a class="tab" :class="[{'on':gradeIndex == -1}]" @click="moveGrade(-1)">전체</a>
              <a class="tab" :class="[{'on':gradeIndex == key}]" v-for="(name , key) in gradeNames"  :key="key" @click="moveGrade(key, name)">
                <template v-if='name.gradeName!="기타"'>{{name.className}}</template>
                <template v-else>외부</template> <!-- {{name.gradeName}} -->
              </a>

              <!--<div id="time" class="tab time">{{date}} {{time}}</div>-->
              <div id="time" class="tab time">{{dateTime}}</div>
              
            </div>
          </template>
          <template v-else>
            <div id="tabs" class="flex_cc tabs" v-if="menuOn">
              <!--
              <a class="tab flex_cc" :class="[{'on':getMenuIndex == key, 'disable': menu.disable, 'bus--': !isEmpty(schoolInfo) && schoolInfo.type==3, 'flex_row mobile' : $common.isMobile() && authInfo.authority != 2}]" v-for="(menu , key) in menuNames"  :key="key" @click="moveMenu(key, menu)">
                -->

              <!--<a class="tab flex_cc" :class="[{'on':menuIndex == key, 'disable': menu.disable, 'bus--': !isEmpty(schoolInfo) && schoolInfo.type==3, 'flex_row mobile' : $common.isMobile() && authInfo.authority != 2}]" v-for="(menu , key) in menuNames"  :key="key">-->
              <router-link :to="menu.router2[0]" tag="a" class="tab flex_cc" :class="[{'on':menuIndex == key, 'disable': menu.disable, 'bus--': !isEmpty(schoolInfo) && schoolInfo.type==3, 'flex_row mobile' : $common.isMobile() && authInfo.authority != 2}]" v-for="(menu , key) in menuNames"  :key="key">
                <template v-if="menu.disable">
                  <img class="menuicon" v-bind:src="menu.imgdisable">
                </template>
                <template v-else-if="menuIndex==key">    
                  <img class="menuicon" v-bind:src="menu.imgon">
                </template>  
                <template v-else>
                  <img class="menuicon" v-bind:src="menu.img">
                </template>

                <span class="menutext" :class="[{'mobile' : $common.isMobile() && authInfo.authority != 2}]">{{menu.name}}</span>
              </router-link>
              <!--</a>-->

              <!--<div id="time" class="tab time">{{date}} {{time}}</div>-->
              
            </div>
          </template>
        </div>
        <div v-else>
          <!--<div class="loading">Loading...</div>-->
        </div>  

        <div class="flex_rc time">{{dateTime}}</div>
      </div>    


    <!--
     <div class="timeContent flex_rc" v-if="!isEmpty(schoolInfo) && schoolInfo.type < 3">
       <img src="@/assets/img/ico_time.png" />
       <div id="time" class="time">{{date}} {{time}}</div>
     </div>-->



    </div>

    </div>

      

</header>
</template>
<style>
.tree{
  --spacing : 2.5rem;
  --radius  : 10px;
  padding   : 0px; /* ik */
  margin-left: -10px;
}

.tree li{
  display      : block;
  position     : relative;
  padding-left : calc(2 * var(--spacing) - var(--radius) - 2px);
  /* ik add */
  text-align   : left;
  padding-top  : 2px;
}

.tree ul{
  margin-left  : calc(var(--radius) - var(--spacing));
  padding-left : 0;
}

.tree ul li{
  border-left : 2px solid #ddd;
}

.tree ul li:last-child{
  border-color : transparent;
}

.tree ul li::before{
  content      : '';
  display      : block;
  position     : absolute;
  top          : calc(var(--spacing) / -2);
  left         : -2px;
  width        : calc(var(--spacing) + 2px);
  height       : calc(var(--spacing) + 1px);
  border       : solid #ddd;
  border-width : 0 0 2px 2px;
}

.tree summary{
  display : block;
  cursor  : pointer;
}

.tree .school_change {
  position:absolute; 
  top:0; right:0;
  cursor: pointer;
  background:#eee;
  color:#000;
  padding:2px;
}

.tree summary::marker,
.tree summary::-webkit-details-marker{
  display : none;
}

.tree summary:focus{
  outline : none;
}

.tree summary:focus-visible{
  outline : 1px dotted #000;
}

.tree li::after,
.tree summary::before{
  content       : '';
  display       : block;
  position      : absolute;
  top           : calc(var(--spacing) / 2 - var(--radius));
  left          : calc(var(--spacing) - var(--radius) - 1px);
  width         : calc(2 * var(--radius));
  height        : calc(2 * var(--radius));
  border-radius : 50%;
  background    : #ddd;
}

.tree summary::before{
  z-index    : 1;
  background : #696 url(../assets/img/expand-collapse.svg) 0 0;
}

.tree details[open] > summary::before{
  background-position : calc(-2 * var(--radius)) 0;
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";
import schoolApi from "@/api/school";
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

//ikkim FCM
import subscribe from "@/services/subscribe";
import noticeApi from '@/api/notice'; //220124
import pushNoticeApi from '@/api/pushNotice'; //240305
//import DeviceCtrl from '@/components/DeviceCtrl';
import statisticsApi from "@/api/statistics"; 
import { isEmpty } from 'typechecker';

import moment from 'moment';
import cn from '@/api/cn';
import commonApi from "@/api/common";

const IS_MOCK = process.env.VUE_APP_IS_MOCK == 'true';
const IS_SAAS = process.env.VUE_APP_IS_SAAS == 'true';
const IS_IOT_CERTI = process.env.VUE_APP_IOT_CERTI == 'true';
const IS_GS_CERTI = process.env.VUE_APP_GS_CERTI == 'true';

var ChosungSearch = require('hangul-chosung-search-js');

/***************** noticeType **********************/
let NOTICE_TYPE_STATION_EMPTY = "IMS_EMPTY"; //연결된 STATION이 없습니다.
let NOTICE_TYPE_INDOOR_EMPTY = "IMI_EMPTY"; //연결된 INDOOR가 없습니다.
let NOTICE_TYPE_CLOCK_EMPTY = "IMC_EMPTY"; //연결된 CLOCCK이 없습니다.
let NOTICE_TYPE_TH_ZERO = "TH_ZERO"; //온도/습도 값이 모두 0입니다.
let NOTICE_TYPE_PM_VALUE = "PM_VALUE"; //미세먼지 센서값이 이상합니다.
let NOTICE_TYPE_TVOC_VALUE = "TVOC_VALUE"; //TVOC 센서값이 이상합니다.
let NOTICE_TYPE_TEMP_DIFF = "TEMP_DIFF"; //냉난방기/냉온의자 등 현재온도와 설정온도간 편차가 큽니다.
let NOTICE_TYPE_MODE_MANUAL = "MANUAL_MODE"; //디바이스가 메뉴얼 모두로 변경되었습니다.
let NOTICE_TYPE_ERROR = "ERROR"; //에러가 발생했습니다.
let NOTICE_TYPE_FILTER_ALARM = "FILTER_ALARM"; //필터알람이 발생했습니다.
let NOTICE_TYPE_OTHER_ALARM = "OTHER_ALARM"; //기타알람이 발생했습니다.
let NOTICE_TYPE_ERRORCODE = "ERROR_CODE"; //에러코드가 있습니다.
let NOTICE_TYPE_ALIVE = "ALIVE"; //gateway alive
let NOTICE_TYPE_APC_EMPTY = "APC_EMPTY"; //APC 디바이스가 등록되지 않은 경우
let NOTICE_TYPE_INOUT_TEMP_DIFF = "INOUT_TEMP_DIFF"; //실내/실외 측정기간의 온도차가 클때.


export default {
  components: { 
    VueSlickCarousel,
  },

  /*
  props: {
    showModal: {
      default: false
    },
  },*/
  data() {
    return {
      orgSuggestion: null,
      suggestion: null,
      schoolName: '',
      schoolNo: '',
      uuid: '',
      slickSettings: {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        autoplay:true,
        autoplaySpeed:10000,
        pauseOnHover : true
      },
      slideText: [
        //{text:"", button:false},
        //{text:"", button:false},
        //{text:"", button:false},
        //{text:"", button:false},
        //{text:"", button:false},
      ],
      gradeIndex:-1,
      modalMenu:null,
      openArr: [false, false, false, false, false, false],
      type: '',
      showModal:false,

      platformTitle:"",
      treeVisible : [
        [false, false, false, false, false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false, false, false, false, false],
        [false, false, false, false, false, false, false, false, false, false, false, false]
      ],
      birdVisible : [false, false, false, false, false, false, false, false, false, false, false, false],
      birdLen: 0,
      timerID: null,
      menuOn: false,
      //date:'',
      //time:'',
      dateTime:'',
      //isShowCMonitorMenu:IS_CMONITOR,
      //isShowCMonitorMenu:false,
      carouselStartAgain: false,

      isSaas: IS_SAAS, //220328
      suggestionNew:[],
      suggestionSearchInput:"",
      //suggestionFilter:["ALL","ㄱ","ㄴ","ㄷ","ㄹ","ㅁ","ㅂ","ㅅ","ㅇ","ㅈ","ㅊ","ㅋ","ㅌ","ㅍ","ㅎ","Aa","●■","CLEAR"], //220705,
      //suggestionFilter:["ALL","CLEAR"], //220705,
      suggestionFilter:[], //220705,
      
      //isCMonitorSchool:false,
      //isAirSchool: false,

      newCMonitor: true,
      newCMRemainTimeStr:'',
      newCM:null,
      //schoolInfo:{},
      isIotCerti: IS_IOT_CERTI,
      isGsCerti: IS_GS_CERTI,

      //menuNames:[],
      menuIndex: -1,
      searchExpand:false,
      noticeExpand:false,
      noticeExpandMini:false,

      bannerImage:null,
      schoolChange: false,

      pushNoticeList: [],
      pushNoticeViewMode: 'E', //default로 NOTICEALL을 표시하자.
      pushNoticeCount:0,
      pageNum: 1,
      count: 1000,
      perPage: this.$common.isMobile() ? 5 : 10,
    }
  },
  computed: {
    ...mapGetters("school", {
      schoolInfo: "schoolInfo",
      school: "schoolList",
      grades: "gradeInfo",
      gradeApcCount: "gradeApcCount"
    }),
    bannerFileEx() {
      return this.$common.getUrl().banner;
    },
    /*
    isCMonitorSchool() {
      //console.log("isCMonitorSchool == "+this.$store.getters['others/useCMonitor']);

      //if(this.isIotCerti==true && this.authInfo.authority!=0) {
      //  return false;
      //}

      if(this.isIotCerti) {
        return false;
      }

      return this.$store.getters['others/useCMonitor'];
    },*/

    isShowSearch() {
      //return this.$store.getters['others/isShowSearch'];
      return false;
    },

    menuNames() {
      let menus = [];
      //let dispMode = this.$common.dispMode(this.authInfo);

      //console.log(`menuNames ${this.isAirSchool}-${this.isCMonitorSchool}-${this.menuOn}-${this.schoolListLength}`)

      if(this.isAirSchool/*dispMode != this.$common.DISP_CMONITOR_ONLY*/) {
        menus.push({name: this.$common.isMobile() && this.authInfo.authority != 2 ? "현황" : "전체현황", index:0, img:require("@/assets/img/ico_main_01.png"), imgon:require("@/assets/img/ico_main_01_on.png"), imgdisable:require("@/assets/img/ico_main_01.png"), 
        router:["/main"], router2:["/"], disable: false});
      }

      if(this.isCMonitorSchool/*dispMode != this.$common.DISP_AIR_ONLY*//* && this.authInfo.authority!=2*/) {
        // || ((dispMode != this.$common.DISP_AIR_ONLY) && (this.isCMonitorSchool && this.newCM != null))
        
        let disable = false;
        //if(dispMode == this.$common.DISP_AIR_CMONITOR_BOTH && this.isCMonitorSchool == false) {
        //  disable = true;
        //}
        menus.push({name: this.$common.isMobile() && this.authInfo.authority != 2 ? "탄소" : "탄소모니터", index:1, img:require("@/assets/img/ico_main_c.png"), imgon:require("@/assets/img/ico_main_c_on.png"), imgdisable:require("@/assets/img/ico_main_c_disable.png"), 
        router:["/cmonitor"], router2:["/cmonitor"], disable: disable});
      }

      if(this.isAirSchool && this.schoolListLength !== 0/*dispMode != this.$common.DISP_CMONITOR_ONLY*/) {
        
        let existApc = this.gradeApcCount && (this.gradeApcCount.totalApc > 0);
        //console.log("this.gradeApcCount==="+JSON.stringify(this.gradeApcCount));
        if(existApc && (this.authInfo.authority==0 || (this.isIotCerti==false && (this.authInfo.authority==1 || this.authInfo.authority == 10)))) {
          menus.push({name: this.$common.isMobile() && this.authInfo.authority != 2 ? "제어" : "기기제어", index:2, img:require("@/assets/img/ico_main_02.png"), imgon:require("@/assets/img/ico_main_02_on.png"), imgdisable:require("@/assets/img/ico_main_02.png"), 
          router:["/settings"], router2:["/settings"], disable: false});
        }

        //사이니지관리
        if(this.isShelter && /*this.$common.isMobile()==false && */(this.authInfo.authority==0 || (this.isIotCerti==false && (this.authInfo.authority==1 || this.authInfo.authority == 10)))) {
          menus.push({name: this.$common.isMobile() ? "화면" : "사이니지", index:10, img:require("@/assets/img/ico_main_signage.png"), imgon:require("@/assets/img/ico_main_signage_on.png"), imgdisable:require("@/assets/img/ico_main_signage.png"), 
          router:["/sinage"], router2:["/sinage"], disable: false});
        }

        if((this.authInfo.authority < 2 || this.authInfo.authority == 10) && this.schoolInfo.type != 7) {
          menus.push({name: this.$common.isMobile() && this.authInfo.authority != 2 ? "통계" : "전문통계", index:3, img:require("@/assets/img/ico_main_03.png"), imgon:require("@/assets/img/ico_main_03_on.png"), imgdisable:require("@/assets/img/ico_main_03.png"), 
          router:["/stat"], router2:["/stat"], disable: false});
        }
        //if(this.authInfo.authority < 2 || this.authInfo.authority == 10) {
        //설치관리는 관리자에게만 허용한다.
        if(this.authInfo.authority == 0 || this.authInfo.authority == 10) {  
          menus.push({name: this.$common.isMobile() && this.authInfo.authority != 2 ? "설치" : "설치관리", index:4, img:require("@/assets/img/ico_main_05.png"), imgon:require("@/assets/img/ico_main_05_on.png"), imgdisable:require("@/assets/img/ico_main_05.png"), 
          router:["/installation"], router2:[(this.isIotCerti == true && this.authInfo.authority!=0) ? "/installation/installation_num" : "/installation/installation_place"], disable: false});
        }
      }
      if(this.schoolListLength !== 0 && this.authInfo.authority==0 || (this.isIotCerti==false && this.isGsCerti==false && (this.authInfo.authority==1 || this.authInfo.authority == 10))) {
        menus.push({name: this.$common.isMobile() && this.authInfo.authority != 2 ? "공지" : "공지사항", index:5, img:require("@/assets/img/ico_main_06.png"), imgon:require("@/assets/img/ico_main_06_on.png"), imgdisable:require("@/assets/img/ico_main_06.png"), 
        router:["/notice"], router2:["/notice"], disable: false});
      }

      menus.push({name: this.$common.isMobile() && this.authInfo.authority != 2 ? "계정" : "계정관리", index:6, img:require("@/assets/img/ico_main_04.png"), imgon:require("@/assets/img/ico_main_04_on.png"), imgdisable:require("@/assets/img/ico_main_04.png"), 
      router:["/ctrl_inq_account"], router2:["/ctrl_inq_account"], disable: false});

      if(this.authInfo.authority == 0 || (this.isIotCerti==false && this.authInfo.authority == 10)) {
        menus.push({name: this.$common.isMobile() && this.authInfo.authority != 2 ? "관리" : "관리자메뉴", index:7, img:require("@/assets/img/ico_main_07.png"), imgon:require("@/assets/img/ico_main_07_on.png"), imgdisable:require("@/assets/img/ico_main_07.png"), 
        router:[ "/admin" ], router2:[(this.isIotCerti == true && this.authInfo.authority!=0) ? "/admin/firmware" : "/admin/school"], disable: false});

        /* implement later
        menus.push({name: this.$common.isMobile() && this.authInfo.authority != 2 ? "지도" : "지도", index:99, img:require("@/assets/img/ico_location_disable.png"), imgon:require("@/assets/img/ico_location_blue.png"), imgdisable:require("@/assets/img/ico_location_disable.png"), 
        router:[ "/map" ], router2:["/map"], disable: false});
        */
      }
      return menus;
    },

    authInfo(){
      return this.$store.getters['auth/authenticationUserInfo'];
    },
    filteredOptions() {
      return [
        { 
          data: this.suggestions.filter(option => {
            if(this.suggestion){
              let inputString = this.suggestion.toLowerCase();
              //console.log("1==="+inputString);
              //console.log("2==="+option.name);
              return option.name.toLowerCase().indexOf(this.suggestion.toLowerCase()) > -1;
            } else {
              //return "";
              return option.name.toLowerCase();
            }
          })
        }
      ];
    },
    suggestions() {
      let suggest = [];
      if(this.school) {
        this.school.forEach(elm => {
          if((elm.name != "에어코리아" && this.authInfo.authority == 10) || this.authInfo.authority == 0) {
            suggest.push({schoolNo: elm.schoolNo, name: elm.name, schoolUuid:elm.uuid});
          }
        });
      }

      return suggest;
    },
    title(){

      if(this.$route.matched){

        let parentNm = this.$route.matched[1].name;
        if(parentNm == 'main') return '실시간현황';
        else if(parentNm == 'info_change') return '정보변경';
        else if(parentNm == 'settings') return '기기제어';
        else if(parentNm == 'stat') return '전문 통계';
        else if(parentNm == 'ctrl_inq_account') {
          let subNm = this.$route.matched[2].name;
          //ikkim FCM
          if(subNm == 'notification')
            return '부가기능 설정'
          else
            return '조회계정 관리';
        }
        else if(parentNm == 'installation') return '설치관리';
        else if(parentNm == 'notice') return '공지사항';
        else if(parentNm == 'admin') return '관리자메뉴';
        //210930
        else if(parentNm == 'private_policy') return '개인정보처리방침';
      }

      return '실시간현황';
    },

    gradeNames() {

      if(this.schoolListLength == 0) {
        return [];
      }

      let grades = this.$store.getters["school/gradeInfo"];
      let gN = grades.map(grade=>{return {gradeName:grade.gradeName,className:grade.className}});

      //alert("gN:"+JSON.stringify(gN));

      let Indoor = [];
      let Others = [];
      for(let i=0; i<gN.length; i++) {
        if(!isNaN(Number(gN[i].gradeName))==true) { //indoor
          Indoor.push(gN[i]);
        } else {
          Others.push(gN[i]); //ik remark 210714
        }
      }
      
      Indoor.sort(function(a, b)  {
        if(parseInt(a.gradeName) > parseInt(b.gradeName)) return 1;
        if(parseInt(a.gradeName) == parseInt(b.gradeName)) return 0;
        if(parseInt(a.gradeName) < parseInt(b.gradeName)) return -1;
      });


      let mySet = new Set();
      let prevGrade = null;
      for(let i=0; i<Indoor.length; i++) {
        if(prevGrade != Indoor[i].gradeName) {
          mySet.add(Indoor[i]);
          prevGrade = Indoor[i].gradeName;
        }
      }
      
      prevGrade = null;
      for(let i=0; i<Others.length; i++) {
        if(prevGrade != Others[i].gradeName) {
          mySet.add(Others[i]);
          prevGrade = Others[i].gradeName;
        }
      }

      //alert(JSON.stringify([...mySet])+" gradeIndex="+this.gradeIndex);

      return [...mySet];
    },

    schoolListLength(){
      return this.$store.getters['school/schoolList'].length;
    },
    isAirSchool(){
      return this.$store.getters['others/isAirSchool'];
    },
    isCMonitorSchool(){
      return this.$store.getters['others/isCMonitorSchool'];
    },
    

    /*
    bannerImage() {
      if(this.schoolInfo.type != undefined && this.schoolInfo.type != 3) {
        //return 'url('+'/iot/image/banner_'+this.bannerFileEx+'.bin'+')';

        

        let today = moment(new Date());
        let todayStr = moment(today).format("YYYYMMDDHHmmss");
        return 'url('+'/iot/image/banner_'+this.bannerFileEx+'.bin'+'?version='+todayStr+')';
      }

      return 'none';
    }
    */
    isShelter() {
			//let url = this.$common.getUrl().url;
			//return (url == this.$common.URL_MY_SMART_BUS || url == this.$common.URL_PORTAL_SMART_BUS || url == this.$common.URL_SHELTER_MISEWATCH);
      return this.$common.isShelter(); 
		}
  },
  
  methods: {
    ...mapActions("auth", {
      logout: "LOGOUT"
    }),
    ...mapActions("school", {
      //school: "schoolInfo",
      changeSchool: "changeUuid"
    }),

    async refresh() {
      //모든 페이지를 다시 가져오게 수정 240509
      window.location.reload(true);
    },

    getMenuIndex() {

      //console.log("menuIndex cur:"+menu);

      //let prevRouter = this.$route.name;

      let menuIndex = -1;
      let fullPath = this.$router.currentRoute.fullPath;

      //console.log("schoolChange="+this.schoolChange);

      /*if(fullPath.includes("private_policy")) {
        menuIndex = 8;
      } else if(this.schoolChange) {
        //School change
        menuIndex = 0;
      } else*/ {
        for(let i=0; i<this.menuNames.length; i++) {
           //console.log(this.menuNames[i].name);
           this.menuNames[i].router.forEach(r => {
            if(fullPath.includes(r)) {
              menuIndex = i;
            }
           });
        }
      }
      
      if(menuIndex == -1 && !fullPath.includes("private_policy")) {
        menuIndex = 0;
      }

      //console.log("getMenuIndex fullPath="+fullPath+" menuIndex="+menuIndex);

      return menuIndex;
    },

    /*
    moveMenu(key, menu) {
      this.menuIndex = key;
      this.onMenuRequested(menu.index);
    },
    */

    onSiteListRequested() {
      this.suggestionSearchInput = "";
      this.suggestionNew = [];
      if(this.searchExpand) {
        this.searchExpand = false;
      } else {
        this.searchExpand = true;
        this.onSchoolFilterButtonClick("ALL");
        this.noticeExpand = false;
      }
    },

    onSchoolNoticeShowHide(school) {
      school.show = !school.show;

      if(this.pushNoticeViewMode == "NOTICEALL" || this.pushNoticeViewMode == "TOTAL") {
        this.pushNoticeList.forEach(sch => {
          if(sch != school && sch.classList.length > 0 && sch.classList[0].length) {
            sch.show = false;
          }
        });
      }
    },

    expandSchoolPushList(schoolName) {
      if(this.pushNoticeViewMode == "NOTICEALL" || this.pushNoticeViewMode == "TOTAL") {
        this.pushNoticeList.forEach(sch => {
          if(sch.classList.length > 0 && sch.classList[0].length) {
            if(sch.schoolName == schoolName) {
              sch.show = true;
            } else {
              sch.show = false;
            }
          }
        });
      }
    },

    async onPushNoticeListRequested() {
      this.noticeExpand = true;
      this.searchExpand = false;
      this.noticeExpandMini = false;

      await this.getPushNoticeList(this.pushNoticeViewMode);
    },
    onPushNoticeListSizeRequested() {
      if(this.noticeExpandMini) {
        this.noticeExpandMini = false;
      } else {
        this.noticeExpandMini = true;
      }
    },
    onPushNoticeListCloseRequested() {
      this.noticeExpand = false;
      this.noticeExpandMini = false;
    },
    converError(error) {
      let errorStr = "";
      if(error) {
        if(error & this.$apc.ERROR_COM) {
          errorStr += "통신,";
        }
        if(error & this.$apc.ERROR_POWER) {
          errorStr += "전원,";
        }
        if(errorStr != "") {
          errorStr = errorStr.substring(0, errorStr.length-1);
        }
      }

      return errorStr;
    },
    converAlarm(alarm) {
      let alarmStr = "";
      if(alarm) {
        /*
        if(alarm & this.$apc.ALARM_FILTER) {
          alarmStr += "필터,";
        }
        */
        if(alarm & this.$apc.ALARM_MOTOR) {
          alarmStr += "모터,";
        }
        if(alarm & this.$apc.ALARM_SENSOR) {
          alarmStr += "센서,";
        }
        if(alarm & this.$apc.ALARM_DOOR) {
          alarmStr += "도어,";
        }
        if(alarmStr != "") {
          alarmStr = alarmStr.substring(0, alarmStr.length-1);
        }
      }

      return alarmStr;
    },

    converNoticeType(elm) {
      let noticeType = elm.noticeType;
      let noticeData = elm.noticeData;
      let noticeYn = elm.noticeYn;
      let apcIrCode = elm.apcIrCode;

		  let notice = "";

      if(noticeType == NOTICE_TYPE_STATION_EMPTY) {
          if(noticeYn == 'Y' || noticeYn == 'YP') {
            if(noticeData) {
              notice += (this.$common.isMobile()) ? "스테이션 연결 체크(에어코리아 연결됨)" : "스테이션이 연결되지 않았습니다. 에어코리아로 연결되었습니다.";
            } else {
              notice += (this.$common.isMobile()) ? "스테이션 연결 체크" : "스테이션이 연결되지 않았습니다.";
            }
          }
          else
            notice += (this.$common.isMobile()) ? "스테이션 연결 정상" : "스테이션이 정상적으로 연결되었습니다.";
      } else if(noticeType == NOTICE_TYPE_INDOOR_EMPTY) {
          if(noticeYn == 'Y' || noticeYn == 'YP')
            notice += (this.$common.isMobile()) ? "인도어 연결 체크" : "인도어가 연결되지 않았습니다.";
          else
            notice += (this.$common.isMobile()) ? "인도어 연결 정상" : "인도어가 정상적으로 연결되었습니다.";
      } else if(noticeType == NOTICE_TYPE_CLOCK_EMPTY) {
          if(noticeYn == 'Y' || noticeYn == 'YP')
            notice += (this.$common.isMobile()) ? "클락 연결 체크" : "클락이 연결되지 않았습니다.";
          else
            notice += (this.$common.isMobile()) ? "클락 연결 정상" : "클락이 정상적으로 연결되었습니다.";
      } else if(noticeType == NOTICE_TYPE_TH_ZERO) {
          if(noticeYn == 'Y' || noticeYn == 'YP')
            notice += (this.$common.isMobile()) ? "온도/습도 0" : "온도/습도 측정값이 모두 0입니다.";
          else
            notice += (this.$common.isMobile()) ? "온도/습도 정상" : "온도/습도 측정값이 정상입니다.";
      } else if(noticeType == NOTICE_TYPE_PM_VALUE) {
          if(noticeYn == 'Y' || noticeYn == 'YP')
            notice += (this.$common.isMobile()) ? `미세먼지 수치에러(${noticeData}㎍/m³)` : "미세먼지 수치("+noticeData+"㎍/m³)를 체크하세요.";
          else
            notice += (this.$common.isMobile()) ? "미세먼지 수치정상" : "미세먼지 수치("+noticeData+"㎍/m³)가 정상입니다.";
      } else if(noticeType == NOTICE_TYPE_TVOC_VALUE) {
          if(noticeYn == 'Y' || noticeYn == 'YP')
            notice += (this.$common.isMobile()) ? `TVOC 수치에러(${noticeData}ppb)` : "TVOC 수치("+noticeData+"ppb)가 높습니다.";
          else
            notice += (this.$common.isMobile()) ? "TVOC 수치정상" : "TVOC 수치("+noticeData+"ppb)가 정상입니다.";
      } else if(noticeType == NOTICE_TYPE_TEMP_DIFF) {
          if(noticeYn == 'Y' || noticeYn == 'YP')
            notice += (this.$common.isMobile()) ? `온도편차 큼(${noticeData}℃)` : "현재온도와 설정온도간 편차가("+noticeData+"℃)가 큽니다.";
          else
            notice += (this.$common.isMobile()) ? "온도편차 정상" : "현재온도와 설정온도간 편차가("+noticeData+"℃)가 정상입니다.";
      } else if(noticeType == NOTICE_TYPE_INOUT_TEMP_DIFF) {
          if(noticeYn == 'Y' || noticeYn == 'YP')
            notice += (this.$common.isMobile()) ? `온도편차 큼(${noticeData}℃)` : "실내-실외 측정기의 온도편차("+noticeData+"℃)가 큽니다.";
          else
            notice += (this.$common.isMobile()) ? "온도편차 정상" : "실내-실외 측정기의 온도편차("+noticeData+"℃)가 정상입니다.";
      } else if(noticeType == NOTICE_TYPE_MODE_MANUAL) {
          if(noticeYn == 'Y' || noticeYn == 'YP') {
            let manualMode = "";
            if(noticeData == 0) manualMode = "OFF";
            else if(noticeData == 1) manualMode = "ON";

            notice += (this.$common.isMobile()) ? `${manualMode} 모드` : `${manualMode} 모드로 전환 되었습니다.`;
          }
          else
            notice += (this.$common.isMobile()) ? "자동제어 모드" : "자동제어 모드로 전환 되었습니다.";
      } else if(noticeType == NOTICE_TYPE_ERROR) {
          if(noticeYn == 'Y' || noticeYn == 'YP') {
            let error = noticeData;

            let errorStr = this.converError(error);
            if(errorStr != "") {
              notice += (this.$common.isMobile()) ? `[${errorStr}] 에러` : `[${errorStr}] 에러가 발생했습니다.`;  
            }
          } else {
            notice += (this.$common.isMobile()) ? "에러 해제" : `에러가 해제되었습니다.`;
          }
      } else if(noticeType == NOTICE_TYPE_FILTER_ALARM) {
          if(noticeYn == 'Y' || noticeYn == 'YP') {
            notice += (this.$common.isMobile()) ? `[필터] 알람` : `[필터] 알람이 발생했습니다.`;  
          } else {
            notice += (this.$common.isMobile()) ? "필터알람 해제" : `필터알람이 해제되었습니다.`;
          }
      } else if(noticeType == NOTICE_TYPE_OTHER_ALARM) {
          if(noticeYn == 'Y' || noticeYn == 'YP') {
            let alarm = noticeData;

            let alarmStr = this.converAlarm(alarm);
            if(alarmStr != "") {
              notice += (this.$common.isMobile()) ? `[${alarmStr}] 알람` : `[${alarmStr}] 알람이 발생했습니다.`;  
            }
          } else {
            notice += (this.$common.isMobile()) ? "기타알람 해제" : `기타알람이 해제되었습니다.`;
          }
      } else if(noticeType == NOTICE_TYPE_ERRORCODE) {
          if(noticeYn == 'Y' || noticeYn == 'YP') {
            let ctrlMethod = this.$apc.apcCtrlMethod(apcIrCode);
            let manufacturer = this.$apc.apcManufacturer(apcIrCode);
            if(ctrlMethod == this.$apc.CTRL_RS485MODBUS && manufacturer == this.$apc.DEVICE_MANUF_SS) {
              //삼성냉난방기 에러코드 처리
              let errorCode = noticeData;
              let errorStr = "";
              
              if(errorCode & 0x8000) {
                notice += '<font color=cyan>' + ((this.$common.isMobile()) ? '제상' : '제상이 발생했습니다.') + '</font>';
              } 

              //if(errorCode & 0x8000) { errorStr += ", 제상"; }
              if(errorCode & 0x4000) { errorStr += ", 트래킹"; }
              if(errorCode & 0x2000) { errorStr += ", R1/R2"; }
              if(errorCode & 0x1000) { errorStr += ", 중계기"; }
              if(errorCode & 0x0FFF) { errorStr += `, 실외기(${errorCode & 0x0FFF})`; }

              if(errorStr != "") {
                errorStr = errorStr.substring(2);

                if(notice != "") {
                  notice += '<br>';  
                }
                
                notice += (this.$common.isMobile()) ? `${errorStr}` : `${errorStr} 에러가 발생했습니다.`;
              }
            } else {
              notice += (this.$common.isMobile()) ? `[${noticeData}] 에러코드` : `[${noticeData} (0x${noticeData.toString(16)})] 에러코드가 발생했습니다.`;
            }
          } else {
            notice += (this.$common.isMobile()) ? `에러코드 해제` : `에러코드가 해제되었습니다.`;
          }
      } else if(noticeType == NOTICE_TYPE_ALIVE) {
          //이 알림은 1시간마다 업데이트 된다.
          //그래서 1시간 이상 업데이트 되지 않으면 에러를 표출한다.

          if(noticeYn == 'Y' || noticeYn == 'YP')
            notice += (this.$common.isMobile()) ? `인터넷 체크` : `인터넷연결 또는 게이트웨이를 체크해 주세요.`;
          else
            notice += (this.$common.isMobile()) ? `인터넷 정상` : `인터넷 또는 게이트웨이가 정상 연결되었습니다.`;
      } else if(noticeType == NOTICE_TYPE_APC_EMPTY) {
          if(noticeYn == 'Y' || noticeYn == 'YP')
            notice += (this.$common.isMobile()) ? `APC디바이스 체크` : `APC디바이스가 셜정되지 않았습니다.`;
          else
            notice += (this.$common.isMobile()) ? `APC디바이스 ${noticeData}개` : `APC디바이스 ${noticeData}개가 설정되었습니다.`;
      } else {
          notice += "알수 없는 알람입니다.";
      }

		  return notice;
	  },
    async clickNoticeLevel(type) {
      await this.getPushNoticeList(type);

      /*
      this.pushNoticeList.forEach((school, idx) => {
        school.forEach((cls, idx2) => {
          let notice = [];
          cls.forEach(elm => {
            if(elm.noticeLevel == type) {
              notice.push(elm);
            }
          });
          this.pushNoticeList[idx][idx2] = notice;
        });
      });
      this.$forceUpdate();
      */
    },
    async clickNoticeAll() {
      await this.getPushNoticeList('NOTICEALL');
    },
    async clickTotal() {
      await this.getPushNoticeList('TOTAL');
    },
    async updatePushPending(cls, elm) {
      let yn;
      if(elm.noticeYn.length == 2) {
        yn = elm.noticeYn.substring(0,1);
      } else {
        yn = elm.noticeYn+'P';
      }

      let param = {
        noticeIdx: elm.noticeIdx,
        noticeYn: yn
      }

      try {
        await pushNoticeApi.updatePushNoticePending(param);

        elm.noticeYn = yn;

        if(this.authInfo.authority != 0) {
          let index = cls.indexOf(elm);
          if(index >= 0) {
            cls.splice(index, 1);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async deletePushNotice(school, cls, elm) {
      //console.log("school="+JSON.stringify(school));
      //console.log("cls="+JSON.stringify(cls)+" elm="+JSON.stringify(elm));

      await pushNoticeApi.deletePushNotice(elm.noticeIdx);
      
      let index = cls.indexOf(elm);
      if(index >= 0) {
        cls.splice(index, 1);
        school.length--;
        if(cls.length == 0) {
          let pushList = school.classList.indexOf(cls);
          if(pushList >= 0) {
            school.classList.splice(pushList, 1);
          }
        }
      }
      //console.log("school2="+JSON.stringify(school));
      //console.log("pushNotice="+JSON.stringify(this.pushNoticeList));
    },
    modifyNotice(resp, viewMode) {
      //console.log("resp="+JSON.stringify(resp));
      this.pushNoticeList = [];

      if(resp.length > 0) {
        let schoolUuid = null;
        let schoolName = null;
        let classNo = null;
        let classList = [];
        let notice = {schoolUuid:null, schoolName:null, classList:[], length:0};

        this.pushNoticeCount = 0;

        resp.forEach(elm => {
          
          //알림이후 APC등록/제거 등으로 apcModel이 null일때는 표시하지 않는데, 다만 TOTAL모드에서는 확인하도록 한다.
          //if(viewMode != 'TOTAL' && (elm.apcInfoNo != 0 && elm.apcModel == null)) {
          //  return;
          //}

          //알림이후 APC등록/제거 등으로 apcModel이 null일때는 지우기 위해 별도 표시를 한다.
          if(elm.apcInfoNo != 0 && elm.apcModel == null) {
            elm.error = true;
          }

          this.pushNoticeCount++;

          //let noticeStr = this.converNoticeType(elm.noticeType, elm.noticeData, elm.noticeYn, elm.apcIrCode);
          let noticeStr = this.converNoticeType(elm);

          if(elm.noticeLevel == 'E') {
            //error
            elm.noticeTypeStr = "<font color=fuchsia>"+noticeStr+"</font>";
          } else if(elm.noticeLevel == 'W') {
            //warning
            elm.noticeTypeStr = "<font color=cyan>"+noticeStr+"</font>";
          } else if(elm.noticeLevel == 'X') {
            //disable
            elm.noticeTypeStr = "<font color=gray>"+noticeStr+"</font>";
          } else {
            //'I' info
            elm.noticeTypeStr = noticeStr;
          }

          if(this.$common.isMobile()) {
            elm.createDateTime = moment(elm.createDateTime).format("MM-DD")+"<br/>"+moment(elm.createDateTime).format("HH:mm");
            if(elm.updateDateTime) {
              elm.updateDateTime = moment(elm.updateDateTime).format("MM-DD")+"<br/>"+moment(elm.updateDateTime).format("HH:mm");
            }
          } else {
            elm.createDateTime = moment(elm.createDateTime).format("YYYY-MM-DD HH:mm");
            if(elm.updateDateTime) {
              elm.updateDateTime = moment(elm.updateDateTime).format("YYYY-MM-DD HH:mm");
            }
          }

          if(schoolUuid != null && schoolUuid != elm.schoolUuid) {
            //NOTICEALL, TOTAL의 경우 LIST가 없어 속도저하가 발생하여 LIST를 접어서 표시한다.
            notice.show = (viewMode == "NOTICEALL" || viewMode == "TOTAL") ? false : true;
            notice.schoolUuid = schoolUuid;
            notice.schoolName = schoolName;
            notice.classList.push(classList);
            notice.length += classList.length;

            this.pushNoticeList.push(notice);
            classList = [];
            classNo = null;

            notice = {schoolUuid:null, schoolName:null, classList:[], length:0};
          }
          if(classNo != null && classNo != elm.classNo) {
            notice.classList.push(classList);
            notice.length += classList.length;

            classList = [];
          }
          schoolUuid = elm.schoolUuid;
          schoolName = elm.schoolName;
          classList.push(elm);
          classNo = elm.classNo;
        });
        //add last
        //NOTICEALL, TOTAL의 경우 LIST가 없어 속도저하가 발생하여 LIST를 접어서 표시한다.
        notice.show = (viewMode == "NOTICEALL" || viewMode == "TOTAL") ? false : true;
        notice.schoolName = schoolName;
        notice.schoolUuid = schoolUuid;
        notice.classList.push(classList);
        notice.length += classList.length;

        this.pushNoticeList.push(notice);
      }

      //만약에 classList에 NOTICE_TYPE_ALIVE 가 존재하면 다른 notice는 보여주지 않는다.
      if(/*viewMode == 'NOTICEALL' || */viewMode == 'E') {
        this.pushNoticeList.forEach(school => {
          school.classList.forEach(cls => {
            let isAlive = cls.filter(c => c.noticeType == NOTICE_TYPE_ALIVE);
            //console.log("cls="+JSON.stringify(cls)+" isAlive="+JSON.stringify(isAlive));
            if(isAlive.length > 0) {
              //NOTICE_TYPE_ALIVE가 있다는 것은 게이트웨이가 동작하지 않는 것이고 그렇다면 다른 notice error는 의미가 없다.
              this.pushNoticeCount -= (cls.length - isAlive.length);
              cls = isAlive;
              cls.length = isAlive.length;
            }
          });
        });
      }

      //console.log("pushNoticeList="+JSON.stringify(this.pushNoticeList));
    },
    modifyNotice_org(resp, viewMode) {
      //console.log("resp="+JSON.stringify(resp));
      this.pushNoticeList = [];

      if(resp.length > 0) {
        let schoolUuid = null;
        let classNo = null;
        let schoolList = [];
        let classList = [];

        resp.forEach(elm => {
          
          //알림이후 APC등록/제거 등으로 apcModel이 null일때는 표시하지 않는데, 다만 TOTAL모드에서는 확인하도록 한다.
          if(viewMode != 'TOTAL' && (elm.apcInfoNo != 0 && elm.apcModel == null)) {
            return;
          }

          let noticeStr = this.converNoticeType(elm.noticeType, elm.noticeData, elm.noticeYn, elm.apcIrCode);

          if(elm.noticeLevel == 'E') {
            //error
            elm.noticeTypeStr = "<font color=fuchsia>"+noticeStr+"</font>";
          } else if(elm.noticeLevel == 'W') {
            //warning
            elm.noticeTypeStr = "<font color=cyan>"+noticeStr+"</font>";
          } else if(elm.noticeLevel == 'X') {
            //disable
            elm.noticeTypeStr = "<font color=gray>"+noticeStr+"</font>";
          } else {
            //'I' info
            elm.noticeTypeStr = noticeStr;
          }

          if(this.$common.isMobile()) {
            elm.createDateTime = moment(elm.createDateTime).format("MM-DD")+"<br/>"+moment(elm.createDateTime).format("HH:mm");
            if(elm.updateDateTime) {
              elm.updateDateTime = moment(elm.updateDateTime).format("MM-DD")+"<br/>"+moment(elm.updateDateTime).format("HH:mm");
            }
          } else {
            elm.createDateTime = moment(elm.createDateTime).format("YYYY-MM-DD HH:mm");
            if(elm.updateDateTime) {
              elm.updateDateTime = moment(elm.updateDateTime).format("YYYY-MM-DD HH:mm");
            }
          }

          if(schoolUuid != null && schoolUuid != elm.schoolUuid) {
            schoolList.push(classList);
            this.pushNoticeList.push(schoolList);
            schoolList = [];
            classList = [];
            classNo = null;
          }
          if(classNo != null && classNo != elm.classNo) {
            schoolList.push(classList);
            classList = [];
          }
          schoolUuid = elm.schoolUuid;
          classList.push(elm);
          classNo = elm.classNo;
        });
        //add last
        schoolList.push(classList);
        this.pushNoticeList.push(schoolList);
      }

      console.log("pushNoticeList="+JSON.stringify(this.pushNoticeList));
    },
    async getPushNoticeList(viewMode) {
      //0: load all for admin
      //1: load notice for user
      let mode = (viewMode == 'TOTAL') ? 0 : 10;
      //let cnt = 0;
      this.pushNoticeViewMode = viewMode;

      try {
        let param = {
          mode: mode,
          noticeLevel: this.pushNoticeViewMode,
          pageNum: this.pageNum,
          count: this.count
        };

        let resp = await pushNoticeApi.getPushNoticeList(param);
        //console.log("getPushNoticeList = "+JSON.stringify(resp));
        if(resp && resp.notice) {
          //cnt = resp.notice.length;
          this.modifyNotice(resp.notice, viewMode);
        }      
      } catch (err) {
        console.error(err);
      }

      //console.log("pushNoticeList="+JSON.stringify(this.pushNoticeList));
      //console.log("cnt="+cnt);

      //return cnt;
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    // clickList(target, index) { 

    //    if(this.authInfo.authority >= 2){
    //      return false;
    //    }
    //   this.openArr = [...this.openArr.map(arr => { return arr = false})];
    //   this.$set(this.openArr, index, true);
    //   /*
    //   if(target == 0) {
    //     this.getDeviceCtrl(target, index-1);
    //   }else {
    //     this.getDeviceCtrl(target, index-4);
    //   }
    //   */
    // },
    typeChange(type) {
      this.type = type;
    },
    refreshTimer : function(){
    },
    openMenu() {
      
      if(this.menuOn) {
        this.menuOn = false;
      }else {
        this.menuOn = true;
      }

/*
      if(document.getElementById("menu").style.display == 'block') {
        document.getElementById("menu").style.display = 'none';
      } else {
        document.getElementById("menu").style.display = 'block';
      }
*/
    },
    openGuide() {
      //this.$router.push("/cmonitor/cmonitor_main");
      if(this.$route.name != "impl_list") {
        this.$router.push("/cmonitor/impl_list");
      }
      this.gradeIndex = -2;
    },
    closeCtrlMenu() {
      this.$set(this, "openArr", [false, false, false, false, false, false]);
      // this.type = this.originType;
    },
    setCtrlStatus(target, num) {
      /*
      if(target == 0) { // 공기청정기
        this.setDeviceCtrl(0, num);
      }else { // 공기순환기
        this.setDeviceCtrl(1, num);
      }
      */
    },

    routerRefreshRequested() {
      //console.log("routerRefreshRequested menuIndex="+this.menuIndex+ " length="+this.menuNames.length);

      if(this.menuIndex >= 0 && this.menuIndex < this.menuNames.length) {
        if(this.getMenuIndex() != this.menuIndex) {
          //console.log("routerRefresh="+this.menuNames[this.menuIndex].router2[0]);
          this.$router.replace(this.menuNames[this.menuIndex].router2[0]);  
        } else if(this.menuIndex == 0 && (this.menuNames[this.menuIndex].router2[0]=='/' || this.menuNames[this.menuIndex].router2[0]=='/cmonitor')) {
          //console.log("routerRefresh22="+this.menuNames[this.menuIndex].router2[0]);
          let today = moment(new Date());
          let todayStr = moment(today).format("SSS");
          //this.$router.push("/cmonitor?date="+todayStr);
          this.$router.replace(this.menuNames[this.menuIndex].router2[0]+'?s='+todayStr);  
        }
      }
    },

    onMenuRequested(menu) {

      //console.log("menuIndex cur:"+menu);
      //========================================= 
      if(1 || this.authInfo.authority == 0) {
        return;
      }
      //========================================= 

      let prevRouter = this.$route.name;

      let menuRouter = 0;
      if(menu == 8) {
        //개인정보처리방침
        menuRouter = 8;
      } else if(menu == 0) {
        //전체현황
        menuRouter = 0;
      } else {
        if(this.menuIndex < 0 || this.menuNames.length <= this.menuIndex) {
          return;
        }

        menuRouter = this.menuNames[this.menuIndex].router[0];
      }

      //console.log("menuIndex="+this.menuIndex+" prevRouter="+prevRouter+" menuRouter="+menuRouter);

      //if(menuRouter=='/main' /*menu == 0*/) { //전체현황
/*
        if(!this.isAirSchool && this.isCMonitorSchool) {
          let today = moment(new Date());
          let todayStr = moment(today).format("YYYYMMDDHHmmss");
          this.$router.push("/cmonitor?date="+todayStr);
        } else {
          if(this.$router.currentRoute.fullPath == "/main/total") {
            this.$router.push("/main/total/0");  
          } else {
            this.$router.push("/main/total");
          }
        }
*/        
        /*
        if(this.$common.dispMode(this.authInfo) == this.$common.DISP_CMONITOR_ONLY) {
            let today = moment(new Date());
            let todayStr = moment(today).format("YYYYMMDDHHmmss");
            this.$router.push("/cmonitor?date="+todayStr);
        } else {
          if(this.$router.currentRoute.fullPath == "/main/total") {
            this.$router.push("/main/total/0");  
          } else {
            this.$router.push("/main/total");
          }
        }*/

        //this.$router.push({ name:"individual", params: { gradeName: name.gradeName}});

      //} 
      //else 
      if(menuRouter=='/cmonitor' /*menu == 1*/) { //탄소모니터
        if(prevRouter != "co_dashboard")
        this.$router.push("/cmonitor/co_dashboard");
      } else if(menuRouter=='/settings' /*menu == 2*/) { //기기제어
        if(this.authInfo.authority == 0) {
          if(prevRouter != "simple")
          //this.$router.push("/settings/simple");
          this.$router.push("/settings/time");
        } else {
          if(prevRouter != "time")
          this.$router.push("/settings/time");
        }

      } else if(menuRouter=='/stat' /*menu == 3*/) { //전문통계
        if(prevRouter != "daily")
        this.$router.push("/stat/date");
      } else if(menuRouter=='/installation' /*menu == 4*/) { //설치관리
        if(this.isIotCerti == true && this.authInfo.authority!=0) {
          if(prevRouter != "installation_num") {
            this.$router.push("/installation/installation_num");
          }
        } else {
          if(prevRouter != "installation_place")
            this.$router.push("/installation/installation_place");
        }
      } else if(menuRouter=='/notice' /*menu == 5*/) { //공지사항
        if(prevRouter != "notice_main")
        this.$router.push("/notice");
      } else if(menuRouter == '/ctrl_inq_account' /*menu == 6*/) { //계정관리
        //if(this.authInfo.authority != 0 || (this.authInfo.authority == 10)) { // 일반관리자
          if(prevRouter != "ctrl_inq_info_change_basic")
          this.$router.push("/ctrl_inq_account/ctrl_inq_info_change_basic");
        //} else {
        //  if(prevRouter != "ctrl_inq_info_change_password")  
        //  this.$router.push("/ctrl_inq_account/ctrl_inq_info_change_password");
        //}
      } else if(menuRouter == '/admin' /*menu == 7*/) { //관리자메뉴
        if(this.isIotCerti == true && this.authInfo.authority!=0) {
          //if(prevRouter != "notice-list")
          //  this.$router.push("/admin/notice-manage");

          if(prevRouter != "Apcfirmware")
            this.$router.push("/admin/firmware");
            
        } else {
          if(0 && this.authInfo.authority == 10) { // 일반관리자
            //if(prevRouter != "notice-list")
            //  this.$router.push("/admin/notice-manage");

            if(prevRouter != "manager-list")
              this.$router.push("/admin/manager");
          } else {
            if(prevRouter != "school-list")
              this.$router.push("/admin/school");
          }
        }
      } else if(menuRouter == 8) { //개인정보처리방침
        if(prevRouter != "private_policy") {
          this.$router.push("/private_policy");
          this.menuIndex = -1;
        }
      } else {
        //if(menuRouter=='/main' /*menu == 0*/) { //전체현황

          if(!this.isAirSchool && this.isCMonitorSchool) {
            let today = moment(new Date());
            let todayStr = moment(today).format("YYYYMMDDHHmmss");
            this.$router.push("/cmonitor?date="+todayStr);
          } else {
            if(this.$router.currentRoute.fullPath == "/main/total") {
              this.$router.push("/main/total/0");  
            } else {
              this.$router.push("/main/total");
            }
          }
        //}
      }

      this.gradeIndex = -2;
    },


    

    async onLogoutRequested() {
      //ikkim unsub when logout ============
      //this.$store.commit('others/setNotiReject', true);


      /* logout시 하기 루틴을 실행하면 FCM을 받지 못하므로, logout시에도 FCM을 받도록 한다.
      let token = this.$store.getters['others/token'];
      let uuid = this.$store.getters['auth/getSchoolUUID'];
      //console.log("logout token="+token+" uuid="+uuid+ " len="+uuid.length);
      subscribe.unSubscribeTokenToTopic(token, uuid);
      //ikkim unsub when logout ============
      */

      //console.log("logout2");

      //clearTimeout(this.timerID)
      //this.timerID = setTimeout(this.updateTreeAndBird, 1000); //지연 실행 설정

      if(!IS_MOCK) {
        await this.logout(this);
      }
      this.$router.push("/login");

    },
    // 정보변경 버튼 클릭 이벤트
    /*
    infoChangeButtonClicked() {
      let url = '/ctrl_inq_account/info_change_password' // 최고관리자, 일반 조회계정 
      if(this.authInfo.authority != 0 || (this.authInfo.authority == 0)) { // 일반관리자
        url = '/ctrl_inq_account/info_change_basic'
      }
      this.$router.push(url);
    },*/
    getSuggestionValue(suggestion) {
      //console.log(suggestion.item.name);
      return suggestion.item.name;
    },
    onSelected(e){
      const vm = this;
      if(e && e.item){
        let value = e.item.name;
        vm.schoolName = value;
        vm.schoolNo = e.item.schoolNo;
      }
      vm.uuid = this.school.filter(e=>e.schoolNo == vm.schoolNo)
                      .map(e=>e.uuid)[0];
    },
    async onSchoolSearchButtonClick() {
      let searchVal = document.getElementById("autosuggest__input").value;
      //console.log("keup uuid="+this.uuid+" searchVal="+searchVal);
      /* ikkim
      //학교이름을 다 치고 enter키를 누르면 학교전환이 되지 않는 버그로 인해 수정함. 210712
      if(this.uuid == null || this.uuid == '') {
        this.uuid = this.school.filter(e=> e.name == searchVal)
                      .map(e=>e.uuid)[0];
      }
      */
      this.uuid = this.school.filter(e=> e.name == searchVal)
                    .map(e=>e.uuid)[0];

      if(this.uuid) {
        await this.changeSchool(this.uuid);
        //  navigation duplicated error 처리를 위한 분기(2020.02.18)
        //console.log(this.$router.currentRoute.fullPath);
        /* ik remove 210714
        if(this.$router.currentRoute.fullPath == '/main/total'){
          this.$router.push("/main/total/0");
        }else{
          this.$router.push("/main/total");
        }
        */
        //ik add 210714
        //ik change : always total when change school 220203
        /*
        if(this.$router.currentRoute.fullPath.indexOf('/main/individual') != -1){
          //console.log("[Header] onSelected searchVal="+searchVal);
          this.$router.push("/main/individual").catch(() => {});
          //ik this.$router.push({name: "individual", params: { gradeName: "1", className:searchVal}}).catch(() => {});

        } else*/ {
          if(this.$router.currentRoute.fullPath == "/main/total") {
            this.$router.push("/main/total/0");  
          } else {
            this.$router.push("/main/total");
          }
        }
        this.menuIndex = 0;

        this.gradeIndex = -1;
        //this.menuIndex = -1;

        //console.log("==>"+this.$router.currentRoute.fullPath);

        //ikkim FCM
        /*
        let token = this.$store.getters['others/token'];
        //console.log("uuid4="+this.uuid);
        //console.log("token4="+token);
        if(token != null) {
          subscribe.subscribeTokenToTopic(token, this.uuid);
        }
        */

        await this.launch(false);

        //ik add 220203
        //get platform title and slide message ===============================================
        //this.carouselStartAgain = true;
/*
        await this.getPlatformTitle();
        await this.getNotiMessage();

        this.updateCMonitor();
*/


        /*
        await this.$nextTick(function() { 
          console.log("carouse="+this.$refs.carousel);
          this.$forceUpdate();

          if(this.$refs.carousel != undefined) {
            this.$refs.carousel.goTo(0);
            this.$refs.carousel.play();
          }
        });
        */

      }
    },

    async onSchoolSearchButtonClickWithName(name, uuid) {
      let searchVal = name;
      //console.log("keup uuid="+this.uuid+" searchVal="+searchVal);
      /* ikkim
      //학교이름을 다 치고 enter키를 누르면 학교전환이 되지 않는 버그로 인해 수정함. 210712
      if(this.uuid == null || this.uuid == '') {
        this.uuid = this.school.filter(e=> e.name == searchVal)
                      .map(e=>e.uuid)[0];
      }
      */
      //console.log("name="+name+" uuid="+uuid);
      if(uuid != null) {
        this.uuid = this.school.filter(e=> e.name == searchVal && e.uuid == uuid)
                      .map(e=>e.uuid)[0];
      } else {
        this.uuid = this.school.filter(e=> e.name == searchVal)
                      .map(e=>e.uuid)[0];
      }

      if(this.uuid) {
        this.$store.dispatch("common/updateDashboardStatusCount", -1);

        await this.changeSchool(this.uuid);
        //  navigation duplicated error 처리를 위한 분기(2020.02.18)
        //console.log(this.$router.currentRoute.fullPath);
        /* ik remove 210714
        if(this.$router.currentRoute.fullPath == '/main/total'){
          this.$router.push("/main/total/0");
        }else{
          this.$router.push("/main/total");
        }
        */

        //ik add 210714
        //ik change : always total when change school 220203
        /*
        if(this.$router.currentRoute.fullPath.indexOf('/main/individual') != -1){
          //console.log("[Header] onSelected searchVal="+searchVal);
          this.$router.push("/main/individual").catch(() => {});
          //ik this.$router.push({name: "individual", params: { gradeName: "1", className:searchVal}}).catch(() => {});

        } else*/ {

/*
          let prevRouter = this.$route.name;

          console.log("prevRouter="+prevRouter);
          console.log("this.schoolInfo="+JSON.stringify(this.schoolInfo));
          console.log("grades="+JSON.stringify(this.grades));

          this.isAirSchool = this.grades.filter(x => x.mwdDevice).length != 0;
          console.log("isAirSchool222="+this.isAirSchool);

          if(!this.isAirSchool && this.isCMonitorSchool) {
            this.$router.push("/cmonitor");  
          } else {
            this.$router.push("/main/total");
          }
*/
          //this.$router.push("/");

          /*
          if(this.$common.dispMode(this.authInfo) == this.$common.DISP_CMONITOR_ONLY) {
            let today = moment(new Date());
            let todayStr = moment(today).format("YYYYMMDDHHmmss");
            this.$router.push("/cmonitor/co_dashboard?date="+todayStr);
          } else {
            if(this.$router.currentRoute.fullPath == "/main/total") {
              this.$router.push("/main/total/0");  
            } else {
              this.$router.push("/main/total");
            }
          }*/

        }

        this.menuOn = false;
        this.schoolChange = true;

        //this.menuIndex = 0;
        //this.menuIndex = this.getMenuIndex();
        this.gradeIndex = -1;
        //this.menuIndex = -1;

        //ikkim FCM
        /*
        let token = this.$store.getters['others/token'];
        //console.log("uuid4="+this.uuid);
        //console.log("token4="+token);
        if(token != null) {
          subscribe.subscribeTokenToTopic(token, this.uuid);
        }
        */

        //ik add 220203
        //get platform title and slide message ===============================================
        //this.carouselStartAgain = true;

        await this.launch(false);

        this.noticeExpandMini = true;

/*
        await this.getPlatformTitle();
        await this.getNotiMessage();

        this.updateCMonitor();
*/


        /*
        await this.$nextTick(function() { 
          console.log("carouse="+this.$refs.carousel);
          this.$forceUpdate();

          if(this.$refs.carousel != undefined) {
            this.$refs.carousel.goTo(0);
            this.$refs.carousel.play();
          }
        });
        */

        this.expandSchoolPushList(this.schoolInfo.name);
      }
    },

    onShowHide() {
      this.suggestionSearchInput = "";
      this.suggestionNew = [];
      if(this.isShowSearch) {
        this.$store.dispatch("others/setIsShowSearch", false);
      } else {
        this.$store.dispatch("others/setIsShowSearch", true);
        this.onSchoolFilterButtonClick("ALL");
      }
    },

    onSchoolFilterButtonClick(filter) {
      let searchVal = filter;
      this.suggestionNew = [];

      //console.log("suggestions="+JSON.stringify(this.suggestions));

      this.suggestions.forEach(elm => {
          if(elm.name != null) {
            if(filter == "Aa") {
              const regex = /^[a-zA-Z]*$/;
              if(regex.test(elm.name[0])) {
                //this.suggestionNew.push({schoolNo: elm.schoolNo, name: elm.name});
                this.suggestionNew.push(elm);
              }
            } else if(filter == "●■") {
              const regex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]+$/;
              if(regex.test(elm.name[0]) == false) {
                //this.suggestionNew.push({schoolNo: elm.schoolNo, name: elm.name});
                this.suggestionNew.push(elm);
              }
            } else {
              let filter2 = null;
              if(filter == "ALL") {
                filter = '';
              } else if(filter == "CLEAR") {
                this.suggestionSearchInput = "";
                return;
              } else if(filter == "ㄱ") {
                filter2 = 'ㄲ';
              } else if(filter == "ㄷ") {
                filter2 = 'ㄸ';
              } else if(filter == "ㅂ") {
                filter2 = 'ㅃ';
              } else if(filter == "ㅅ") {
                filter2 = 'ㅆ';
              } else if(filter == "ㅈ") {
                filter2 = 'ㅉ';
              }

              if(ChosungSearch.isSearch(filter, elm.name[0]) == true) { // true, 초성 일치  
                //this.suggestionNew.push({schoolNo: elm.schoolNo, name: elm.name});
                this.suggestionNew.push(elm);
              }
              if(filter2 != null) {
                if(ChosungSearch.isSearch(filter2, elm.name[0]) == true) { // true, 초성 일치  
                  //this.suggestionNew.push({schoolNo: elm.schoolNo, name: elm.name});
                  this.suggestionNew.push(elm);
                }
              }
            }
          }
      });

      //console.log("ss="+JSON.stringify(this.suggestionNew));
    },

    searchSchool(e) {
      let text = e.target.value;
      //console.log(text);

      this.suggestionNew = [];
      
      if(text !== '') {
        this.suggestionNew = this.suggestions.filter(elm => {
            //let inputString = this.suggestion.toLowerCase();
            //console.log("1==="+elm.name);
            //console.log("2==="+elm.name.toLowerCase().indexOf(text.toLowerCase()));
            //return elm.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
            if(elm.name.toLowerCase().indexOf(text.toLowerCase()) > -1) {
              return elm;
            }
        });
      } else {
        this.onSchoolFilterButtonClick("ALL");
      }
    },

    /*
    clickTitle() {
      if(this.school.length == 0) {
        if(this.$router.currentRoute.name != 'school-list') {
          this.$router.push("/admin/school");
        }
      }else {
        this.$router.push("/");
      }
    },
    */
    
    moveGrade(gradeIndex, name){
        //console.log("this.gradeIndex="+this.gradeIndex+" gradeIndex="+gradeIndex);
        if((gradeIndex != this.gradeIndex) || (this.gradeIndex == -2)) {
          if(gradeIndex == -1) {
            //total
            if(this.$router.currentRoute.fullPath == "/main/total") {
              this.$router.push("/main/total/0");  
            } else {
              this.$router.push("/main/total");
            }
          } else {
            //individual
            this.$router.push({ name:"individual", params: { gradeName: name.gradeName}});
          }
          
          this.gradeIndex = gradeIndex;
          //this.menuIndex = -1;
        }
    },

    /**
     * 숫자에 패딩 0을 붙임
     * 
     */
    zeroPadding : function(num, digit) {
        let zero = '';
        for(let i = 0; i < digit; i++) {
            zero += '0';
        }
        return (zero + num).slice(-digit);
    },
    updateTime: function() {
      //let cd = new Date();
      //this.time = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2)
      //this.date = this.zeroPadding(cd.getFullYear(), 4) + '-' + this.zeroPadding(cd.getMonth()+1, 2) + '-' + this.zeroPadding(cd.getDate(), 2);
    
      //document.getElementById("time").innerHTML = date+' '+time;

      let then = moment("2031-01-01 00:00:00");
      let now = moment(new Date());

      this.dateTime = now.format("YYYY-MM-DD HH:mm");

      let diff = moment.utc(moment(then,"DD/MM/YYYY HH:mm:ss").diff(moment(now,"DD/MM/YYYY HH:mm:ss"))).format("H시간 m분");
      let diffDays = then.diff(now, 'days');

      this.newCMRemainTimeStr = this.numberWithCommas(diffDays)+"일 " + diff;
      //console.log("time========="+this.newCMRemainTimeStr+" "+this.dateTime);
    },

    async updateCMonitor_New() {

      if(this.schoolInfo == null) {
        return;
      }

      try {
        let param = {};
        param.schoolNo = this.schoolInfo.schoolNo;
        param.year = moment(new Date()).format("YYYY");
        //console.log("updateCMonitor_New="+JSON.stringify(param));
        this.newCM = await cn.getStatisticsSave(param);
        //console.log("newCM = "+JSON.stringify(this.newCM));
        if(this.newCM && this.newCM.sum && this.newCM.target && this.newCM.target.saveKgCo2 != 0 && this.newCM.list != null) {
        //if(this.newCM.list != null) {

          //if(this.newCM.sum.co2SaveRate > 1) this.newCM.sum.co2SaveRate = 1;
          let rate = this.newCM.sum.saveSumRate;

          /*
          if(rate < 0) {
            this.slideText[1] = {text:"탄소절감량이 너무 적습니다.", button:false};
          } else if(rate < 50) {
            this.slideText[1] = {text:"조금만 더 탄소중립 실천활동을 합시다.", button:false};
            //this.slideText.splice(1, 1, {text:"조금만 더 탄소중립 실천활동을 합시다.", button:false});
          } else {
            this.slideText[1] = {text:"잘하고 있습니다. 100% 목표를 위해 우리모두 파이팅.", button:false};
            //this.slideText.splice(1, 1, {text:"잘하고 있습니다. 100% 목표를 위해 우리모두 파이팅.", button:false});
          }

          //this.slideText[1] = {text:"탄소절감목표 " + this.newCM.target.saveKgCo2 + "% 대비 " + rate + "% 달성하였습니다.", button:false};
          //this.slideText[1] = {text:"달성하였습니다.", button:false};
          //this.slideText.splice(2, 1, {text:"AAAA.", button:false});
          */

          //console.log("rate="+rate);
          if(rate > 100) {
            rate = 100;
          }

          let tree = parseInt((rate/100) * 144);
  //              let tree = 4;
          let bird = 3;

          let j=0;
          let tcnt = parseInt(tree/12);

          console.log("rate="+rate+" tree:"+tree+" bird:"+bird + " tcnt:"+tcnt+ "  birdLen:"+this.birdLen);

          //clear trees
          for(j=0; j<12; j++) {
            //let svg = document.getElementById("svg"+j).childNodes;
            for(let i=0; i<12; i++) {
              //svg[i].classList.remove('on');
              this.treeVisible[j][i] = false;
            }
          }

          //draw tree and leaves
          for(j=0; j<tcnt; j++) {
            //let svg = document.getElementById("svg"+j).childNodes;
            for(let i=0; i<12; i++) {
              //svg[i].classList.add('on');
              this.treeVisible[j][i] = true;
            }
          }

          //draw remain leaves
          //let svg = document.getElementById("svg"+j).childNodes;
          for(let i=0; i<tree%12; i++) {
            //svg[i].classList.add('on');
            this.treeVisible[j][i] = true;
          }

          if(this.birdLen != bird) {
            //clear bird
            for(let i=0; i<12; i++) {
              //document.getElementById("bird"+i).style.display = 'none';
              this.birdVisible[i] = false;
            }

            //draw bird
            for(let i=0; i<bird; i++) {
              //document.getElementById("bird"+i).style.display = 'block';
              this.birdVisible[i] = true;
            }

            this.birdLen = bird;
          }

          //아래를 넣어야지 F5로 Refresh시 나무가 업데이트 된다.
          //await this.$nextTick(function() { 
          //  this.$forceUpdate();
          //});
        } else {
          this.newCM = null;
        }
          
          
      } catch (err) {
        console.error(err);
      }
    },

    async checkIsAirCMonitorSchool() {

      if(this.schoolInfo == null) {
        //this.isAirSchool = false;
        //this.isCMonitorSchool = false;
        this.$store.commit("others/setIsAirSchool", false);
        this.$store.commit("others/setIsCMonitorSchool", false);
        this.$store.commit("others/setLeftOverDeviceType", 0);

        //this.menuIndex = 0;
        this.onMenuRequested(0/*this.menuIndex*/);

        this.menuOn = true;
        return;
      }

      try {

        //let dispMode = this.$common.dispMode(this.authInfo);
        //if(dispMode == this.$common.DISP_AIR_ONLY) {
        //  this.isCMonitorSchool = false;
        //} else {
          //this.isCMonitorSchool = true;
          let resp = await cn.checkIsCMonitorSchool(this.schoolInfo.schoolNo);
          //console.log("$$$$$$$$$ = "+JSON.stringify(resp));
          let isCMonitorSchool = false;
          let leftOverDeviceType = 0;
          if(resp && resp.data != null) {
            isCMonitorSchool = true;
            leftOverDeviceType = resp.data.leftOverDeviceType;
          }
          this.$store.commit("others/setIsCMonitorSchool", isCMonitorSchool);
          this.$store.commit("others/setLeftOverDeviceType", leftOverDeviceType);
          
        //}

        //console.log("grades="+JSON.stringify(this.grades));

        //this.isAirSchool = this.grades.filter(x => x.mwdDevice).length != 0;
        //this.isAirSchool = this.grades && this.grades.length > 0;
        let isAirSchool = this.grades.filter(x => x.enable == 'Y').length != 0;
        this.$store.commit("others/setIsAirSchool", isAirSchool);

        console.log("checkIsAirCMonitorSchool OK isCMonitorSchool==="+this.isCMonitorSchool+" menuIndex="+this.menuIndex+" isAirSchool="+this.isAirSchool);

        //if(prevRouter == 'login' || prevRouter == 'ctrl_inq_info_change_password') {

        //this.menuIndex = this.getMenuIndex();   
        //console.log("isAirSchool111="+this.isAirSchool+" this.menuIndex="+this.menuIndex);

        //this.onMenuRequested(this.menuIndex);

        //학교를 바꿀때는 전체현황을 이동한다.
        /*
        if(this.menuIndex == 999) {
          this.menuIndex = 0;
          this.onMenuRequested(this.menuIndex);
        } else {
          this.menuIndex = this.getMenuIndex();
        }

        //개인정보처리방침 메뉴일때는 자동으로 전체현황으로 간다.
        if(this.menuIndex == 8) {
          this.menuIndex = 0;
          this.onMenuRequested(this.menuIndex);
        }
        */

        this.schoolChange = false;
        this.menuIndex = this.getMenuIndex();

        this.menuOn = true;

/*
          if(!this.isAirSchool && this.isCMonitorSchool) {
            let today = moment(new Date());
            let todayStr = moment(today).format("YYYYMMDDHHmmss");
            this.$router.push("/cmonitor?date="+todayStr);
          } else {
            if(this.$router.currentRoute.fullPath == "/main/total") {
              this.$router.push("/main/total/0");  
            } else {
              this.$router.push("/main/total");
            }
          }
*/
        //}

      } catch (err) {
        //console.error(err);
        //this.isCMonitorSchool = false;
        this.$store.commit("others/setIsCMonitorSchool", false);
        this.$store.commit("others/setLeftOverDeviceType", 0);

        //this.isAirSchool = true; //this.grades.filter(x => x.mwdDevice).length != 0;
        this.$store.commit("others/setIsAirSchool", true);

        //학교를 바꿀때는 전체현황을 이동한다.
        /*
        if(this.menuIndex == 999) {
          this.menuIndex = 0;
          this.onMenuRequested(this.menuIndex);
        } else {
          this.menuIndex = this.getMenuIndex();
        }

        //개인정보처리방침 메뉴일때는 자동으로 전체현황으로 간다.
        if(this.menuIndex == 8) {
          this.menuIndex = 0;
          this.onMenuRequested(this.menuIndex);
        }
        */

        this.schoolChange = false;
        this.menuIndex = this.getMenuIndex();

        this.menuOn = true;

        //console.error("checkIsAirCMonitorSchool ERROR isCMonitorSchool==="+this.isCMonitorSchool+" menuIndex="+this.menuIndex+" isAirSchool="+this.isAirSchool);
        console.log("isCMonitorSchool==="+this.isCMonitorSchool+" isAirSchool="+this.isAirSchool);
      }
    },
    
    async updateCMonitor() {
      
      //IS_CMONITOR
      if(!this.isCMonitorSchool) {
        return;
      }

      if(this.newCMonitor) {
        await this.updateCMonitor_New();
        return;
      }

      try{
        
        let uuid = this.$store.getters['auth/getSchoolUUID'];

        //console.log("uuid==="+uuid);

        if(uuid != null) {

          let param = {
            uuid : uuid
          };

          let resp = await statisticsApi.getUseHistory(param);
          if(resp) {
            let cstatus = this.$cmonitor.costatus(resp.data, this.schoolInfo.name, this.slideText); 

            if(cstatus != null) {

              console.log("cstatus = "+JSON.stringify(cstatus));

              /*
              if(cstatus.totalApAcClassLen > 0) {
                this.isShowCMonitorMenu = true;
              } else {
                this.isShowCMonitorMenu = false;
              }
              */

              let tree = parseInt(cstatus.progress * 144);
//              let tree = 4;
              let bird = 3;

              let j=0;
              let tcnt = parseInt(tree/12);

              console.log("tree:"+tree+" bird:"+bird + " tcnt:"+tcnt+ "  birdLen:"+this.birdLen);

              //clear trees
              for(j=0; j<12; j++) {
                //let svg = document.getElementById("svg"+j).childNodes;
                for(let i=0; i<12; i++) {
                  //svg[i].classList.remove('on');
                  this.treeVisible[j][i] = false;
                }
              }

              //draw tree and leaves
              for(j=0; j<tcnt; j++) {
                //let svg = document.getElementById("svg"+j).childNodes;
                for(let i=0; i<12; i++) {
                  //svg[i].classList.add('on');
                  this.treeVisible[j][i] = true;
                }
              }

              //draw remain leaves
              //let svg = document.getElementById("svg"+j).childNodes;
              for(let i=0; i<tree%12; i++) {
                //svg[i].classList.add('on');
                this.treeVisible[j][i] = true;
              }

              if(this.birdLen != bird) {
                //clear bird
                for(let i=0; i<12; i++) {
                  //document.getElementById("bird"+i).style.display = 'none';
                  this.birdVisible[i] = false;
                }

                //draw bird
                for(let i=0; i<bird; i++) {
                  //document.getElementById("bird"+i).style.display = 'block';
                  this.birdVisible[i] = true;
                }

                this.birdLen = bird;
              }

              this.slideText = cstatus.slideText;

              //아래를 넣어야지 F5로 Refresh시 나무가 업데이트 된다.
              await this.$nextTick(function() { 
                this.$forceUpdate();
              });
            }
          }
        }
      }catch(err){
        console.error(err);
      }
    },

    async getPlatformTitle() {
      let uuid = this.$store.getters['auth/getSchoolUUID'];
      //console.log("uuid1 = "+uuid);
      //platform name===============================================
      try {
        if(uuid != null) {
          let resp = await noticeApi.getNoticeByTitle("{{TITLE}}", uuid);
          //console.log("$$$$$$$$$ = "+JSON.stringify(resp));
          
          if(resp != null && resp.contents != null) {
            //console.log(resp.contents);
            this.$store.commit("others/setPlatformTitle", resp.contents);
            this.platformTitle = resp.contents;
            return;
          }
          //setting default value
          this.platformTitle = "에너지환경케어서비스";
        } else {
          this.platformTitle = "사이트를 등록해 주세요"; //220328
        }
      } catch (err) {
        console.error(err);
      }

    },
    async getNotiMessage() {

      //CMonitor가 false이더라도 carousel message는 display한다.
      //스마트미세먼지 정류장등에서...
      //if(!IS_CMONITOR) {
      //  return;
      //}

      if(this.newCMonitor) {
        return;
      }

      let uuid = this.$store.getters['auth/getSchoolUUID'];
      //console.log("uuid2 = "+uuid);

      try {
        if(uuid != null) {
          if(!IS_MOCK) {
            let resp = null;
            resp = await noticeApi.getNoticeByTitle("{{MESSAGE}}", uuid);
            console.log("######### = "+JSON.stringify(resp));

            this.slideText = [];
            if(resp != null && resp.contents != null) {
              console.log(JSON.stringify(resp.contents));
              const obj = JSON.parse(resp.contents);
              //console.log("json:"+JSON.stringify(obj));
              //this.$store.commit("others/setPlatformTitle", resp.contents);
              //this.platformTitle = resp.contents;
              if(obj != null && obj.length > 0) {

                this.carouselStartAgain = true;
                this.slideText = obj;

                
                //forced update
                /*
                await this.$nextTick(function() { 
                  console.log("carousel2="+this.$refs.carousel);
                  this.$forceUpdate();

                  if(this.$refs.carousel != undefined) {
                    this.$refs.carousel.goTo(0);
                    this.$refs.carousel.play();
                  }
                });
                */

               return;
              }
            } 
          }///* else {

            //setting default value
            //this.slideText[0] = {text:"테스트 메시지 1입니다. 테스트 메시지 1입니다. 테스트 메시지 1입니다. 테스트 메시지 1입니다.", button:false};
            //this.slideText[1] = {text:"두번째 긴 테스트 메시지 입니다. 두번째 긴 테스트 메시지 입니다. 두번째 긴 테스트 메시지 입니다.", button:false};
//            this.carouselStartAgain = true;
          //}*/

          /*
          this.carouselStartAgain = true;
          this.slideText = [
            {text:"$1는 현재까지 탄소배출을 $2kg 줄였습니다.", button:true},
            {text:"줄어든 탄소배출량은 $1 $2그루를 심는 것과 같습니다.", button:true},
            {text:"탄소배출을 줄인만큼 이 공간은 나무가 울창한 숲이 됩니다.", button:false},
            {text:"바로지금, 나부터, 더 늦기전에 2050!!! 탄소중립2050을 실천하여 건강한 지구를 만듭시다.", button:false}
          ];
          */
          //console.log("slideText2="+JSON.stringify(this.slideText));
        }
      } catch (err) {
        console.error(err);
      }
    },

    isEmpty(param) {
      if(param == null) return true;

      return Object.keys(param).length === 0;
    },

    async launch(isInit) {
      if( this.timerID != null ){       //현재 시간 interval 삭제
        clearInterval(this.timerID);
        this.timerID = null;
      }

      //this.schoolInfo = this.$store.getters['school/schoolInfo'];
      //console.log("pushNoticeViewMode="+this.pushNoticeViewMode);

      if(this.schoolInfo && this.schoolInfo.schoolNo) {
        await this.$store.dispatch('school/changeSchoolList');
        await this.checkIsAirCMonitorSchool();

        await this.getPlatformTitle();
        await this.getNotiMessage();
        await this.getBannerImage("banner_"+this.bannerFileEx);
        if(isInit) {
          if(this.schoolInfo && this.$common.isShelter/*(this.schoolInfo.type == 3 || this.schoolInfo.type == 5)*/) {
            await this.getPushNoticeList(this.pushNoticeViewMode);
            if(this.pushNoticeCount == 0) {
              await this.getPushNoticeList('NOTICEALL');
            }
            //console.log("1 pushNoticeCount = "+this.pushNoticeCount);
          }
          
          //console.log("schoollist="+this.schoolListLength);
          if(this.schoolListLength == 0) {
            this.menuIndex = this.getMenuIndex();
          }
        }

        this.updateTime();
        this.updateCMonitor();
        this.timerID = setInterval(function(){
          this.updateTime();
          this.updateCMonitor();
        }.bind(this), 1000 * 60); //1분마다 업데이트

        if(this.isShowSearch) {
          this.onSchoolFilterButtonClick("ALL");
        }
      } else {
        let unsubscribe = await this.$store.subscribe(async (mutation, state) => {
          //console.log("mutation.type==="+mutation.type);
          if (mutation.type === 'school/setSchool') {

            //this.schoolInfo = this.$store.getters['school/schoolInfo'];
            
            await this.$store.dispatch('school/changeSchoolList');
            await this.checkIsAirCMonitorSchool();

            await this.getPlatformTitle();
            await this.getNotiMessage();
            await this.getBannerImage("banner_"+this.bannerFileEx);
            if(isInit) {
              if(this.schoolInfo && this.$common.isShelter/*(this.schoolInfo.type == 3 || this.schoolInfo.type == 5)*/) {
                await this.getPushNoticeList(this.pushNoticeViewMode);
                if(this.pushNoticeCount == 0) {
                  await this.getPushNoticeList('NOTICEALL');
                }
                //console.log("2 pushNoticeCount = "+this.pushNoticeCount);
              }
              
              //console.log("schoollist2="+this.schoolListLength);
              if(this.schoolListLength == 0) {
                this.menuIndex = this.getMenuIndex();
              }
            }

            this.updateTime();
            this.updateCMonitor();
            this.timerID = setInterval(function(){
              this.updateTime();
              this.updateCMonitor();
            }.bind(this), 1000 * 60); //1분마다 업데이트

            unsubscribe();

            if(this.isShowSearch) {
              this.onSchoolFilterButtonClick("ALL");
            }
          }
        });
      }

/*
      await this.getPlatformTitle();
      await this.getNotiMessage();

      this.updateTime();
      this.updateCMonitor();
      this.timerID = setInterval(function(){
        this.updateTime();
        this.updateCMonitor();
      }.bind(this), 1000 * 60); //1분마다 업데이트
      */
    },

    // School, Class 정보 조회
    async getBannerImage(bannerFileName){
      //console.log("bannerFileName="+bannerFileName + " schoolInfo="+JSON.stringify(this.schoolInfo));
      if(this.schoolInfo == null || this.schoolInfo.type == undefined || this.schoolInfo.type == 3) {
        this.bannerImage = 'none';
      } else {

        await commonApi.getBannerImage(bannerFileName).then(resp => {
          //console.log("bannerFileName="+bannerFileName+" resp="+JSON.stringify(resp));
          if(resp.banner){
              let today = moment(new Date());
              let todayStr = moment(today).format("YYYYMMDDHHmmss");
              this.bannerImage = 'url('+'/iot/image/banner_'+this.bannerFileEx+'.bin'+'?version='+todayStr+')';

          } else {
            this.bannerImage = 'none';
          }
        })
        .catch(err => console.error("getBannerImage fail."));
        //.catch(err => console.error(err));
      }
    }
 },
 async created() {

  //this.$store.dispatch('school/changeSchoolList');
},
async mounted() {

  await this.launch(true);

/*
  /////////////////////
  this.schoolInfo = this.$store.getters['school/schoolInfo'];

  //add
  if(this.schoolInfo.schoolNo) {
    await this.launch();
  } else {
    let unsubscribe = await this.$store.subscribe(async (mutation, state) => {
      //console.log("mutation.type==="+mutation.type);
      if (mutation.type === 'school/setSchool') {
        this.schoolInfo = this.$store.getters['school/schoolInfo'];
        
        await this.launch();

        unsubscribe();
      }
    });
  }
  /////////////////////
*/

  //get platform title and slide message ===============================================
  /*
  await this.getPlatformTitle();
  await this.getNotiMessage();

  this.updateTime();
  this.updateCMonitor();
  this.timerID = setInterval(function(){
    this.updateTime();
    this.updateCMonitor();
  }.bind(this), 1000 * 60); //1분마다 업데이트
  */




  //console.log("schoolInfo="+JSON.stringify(this.schoolInfo)+" schoolList="+this.schoolListLength);
  //schoolInfo가 하나도 없을때는 관리자메뉴 index로 세팅한다.
  
  //if(this.schoolListLength == 0 || this.isEmpty(this.schoolInfo)) {
  //  this.menuIndex = 6; //goto admin menu
  //}

  /*
  setTimeout(() => {

    this.menuIndex = this.getMenuIndex();
    this.menuOn = true;

    console.log("show Menu menuIndex="+this.menuIndex+" menuOn="+this.menuOn);

    //개인정보처리방침 메뉴일때는 자동으로 전체현황으로 간다.
    if(this.menuIndex == 8) {
      this.menuIndex = 0;
      this.onMenuRequested(this.menuIndex);
    }
  }, 500);
  */
},
beforeDestroy() {
    //console.log("beforeDestroy2", this.timerID);

    if( this.timerID != null ){       //현재 시간 interval 삭제
      clearInterval(this.timerID);
      this.timerID = null;
    }
  },
watch: {
  menuIndex:  function (to, from) {
    //console.info("@@@ menuIndex "+this.menuIndex+ " " + from+"->"+to);
    if(from != to) {
      this.menuIndex = to;
      this.routerRefreshRequested();
    }
  },
  schoolChange: function (to, from) {
    //console.info("@@@ schoolChange "+from+" "+to+" index="+this.menuNames[this.menuIndex].index);
    if(to==false && from==true) {
      if(this.menuNames[this.menuIndex].index != 99) {
        this.menuIndex = 0;
      }
      this.routerRefreshRequested();
    }
  },
  '$route.name': function (to, from) {
    //console.info("@@@ router "+from+" "+to);
    if(from=='ctrl_inq_info_change_password' && to=='total') {
      //비밀번호 변경메뉴에서 취소를 눌렀을때 처리.
      this.menuIndex = 0;
    } else {
      this.menuIndex = this.getMenuIndex();
    }
  },

  /*
  authInfo : function() {
    //logout(authInfo={})일때는 처리하지 않는다.
    if(!this.isEmpty(this.authInfo)) {
      this.menuIndex = this.getMenuIndex();
      console.log("watch menuIndex=="+this.menuIndex);

      //개인정보처리방침 메뉴일때는 자동으로 전체현황으로 간다.
      if(this.menuIndex == 8) {
        this.menuIndex = 0;
        this.onMenuRequested(this.menuIndex);
      }

      this.launch();
    }
  },*/
  slideText : {
    handler: function(newVal, oldVal) {
      //console.log("▶newVal="+JSON.stringify(newVal)+"\n▶oldVal="+JSON.stringify(oldVal)+"\n▶carouselStartAgain="+this.carouselStartAgain);

      if(newVal != null) {

        //console.log("###### length="+this.slideText.length);

        this.$forceUpdate();

        if(oldVal == null || newVal.length != oldVal.length || this.carouselStartAgain) {
          
          if(this.$refs.carousel != undefined) {
            this.$refs.carousel.goTo(0);
            this.$refs.carousel.play();
          }

          console.log("+++++ carousel start again +++++");
          this.carouselStartAgain = false;
        }
      }
    },
    deep: true
  },
  gradeNames(val) {
    if(val != null && val.length > 0) {
      let curPath = this.$router.currentRoute.fullPath;
      //console.log("curPath==="+curPath);

      if(curPath.includes("/main/total")) {
        this.gradeIndex = -1;
      } else if(curPath.includes("/main/individual")) {  
        let paths = curPath.split("/");
        if(paths.length > 0) {
          let gn = paths[paths.length-1];
          //console.log("gradeName: "+gn+" val="+JSON.stringify(val));
          this.gradeIndex = val.findIndex(e => {
            //console.log(JSON.stringify(e));
            return e.gradeName == gn;
          });
        }
      } else {
        this.gradeIndex = -2;
      }

      //console.log("this.gradeIndex == "+this.gradeIndex);
    }
  }
}  
}

</script>