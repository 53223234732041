import * as jwt from "jsonwebtoken";
import schoolApi from "@/api/school";
import auth from "@/api/auth";
import crypto from "@/lib/crypto";

import * as typechecker from 'typechecker';
import { authorities } from '@/consts/defs';
import moment from 'moment';

const IS_MOCK = process.env.VUE_APP_IS_MOCK == 'true'
const IS_SAAS = process.env.VUE_APP_IS_SAAS == 'true'
const IS_IOT_CERTI = process.env.VUE_APP_IOT_CERTI == 'true';
const IS_APP_DEV = process.env.VUE_APP_DEV == 'true';

function localStorageKey() {
  return `misse`;
}

function fnJwtVerify(authentication){
  try {
    let decoded = null;
    let authenticationObj = {};
    let authUserInfo = authentication.userInfo;
    if(authUserInfo){
      decoded = jwt.verify(authUserInfo, authentication.publicKey);
      authentication['decoded'] = JSON.parse(decoded.sub);
      if (authentication.decoded && typechecker.isArray(authentication.decoded.authorities)) {
        authentication.decoded.adminYn = (authentication.decoded.authorities
          .map(e => e.authority)
          .filter(authority => typechecker.isString(authority)
            && authority === authorities.ADMIN)
          .length > 0)? 'Y' : 'N';
      }
    }

    Object.keys(authentication).forEach(key => {
      if(key !== "userInfo"){
        authenticationObj[key] = authentication[key];
      }
    });

    return authenticationObj;
  } catch (err) {
    throw err;
  }
}

/*
function isJson(item) {
  let value = typeof item !== "string" ? JSON.stringify(item) : item;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }

  return typeof value === "object" && value !== null;
}
*/

function convertJson(item) {
  let value = typeof item !== "string" ? JSON.stringify(item) : item;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return {};
  }

  if(typeof value === "object" && value !== null) {
    return value;
  }

  return {};
}

const state = {
  authentication: null,
  localStorageKey: localStorageKey()
};

const getters = {
  localStorageKey: (state) => state.localStorageKey,
  isAuthenticated: (state) => {
    let accessToken = null;
    if (!state.authentication) {
      if (localStorage.getItem(state.localStorageKey)) {
        state.authentication = JSON.parse(localStorage.getItem(state.localStorageKey));
      } else {
        return false;
      }
    }

    accessToken = state.authentication.accessToken;

    if (!state.authentication.publicKey || !accessToken) {
      return false;
    }

    try {
      jwt.verify(accessToken, state.authentication.publicKey, function (err) {
        if (err) return false;
      });
      return true;
    } catch (err) {
      return false;
    }
  },
  isAdmin: (state) => {
    let retVal = false;
    if (!state.authentication) {
      if (localStorage.getItem(state.localStorageKey)) {
        state.authentication = JSON.parse(localStorage.getItem(state.localStorageKey));
      }
    }

    if(state.authentication && state.authentication.decoded){
      retVal = ((state.authentication.decoded.authority === 0 || state.authentication.decoded.authority === 10 ) ? true: false);
    }

    return retVal;
  },

  accessToken: (state) => {
    if (!state.authentication) {
      if (localStorage.getItem(state.localStorageKey)) {
        state.authentication = JSON.parse(localStorage.getItem(state.localStorageKey));
      } else {
        return '';
      }
    }
    return (state.authentication ? state.authentication.accessToken : null)
  },
 
  publicKey: (state) => {
    if (!state.authentication) {
      if (localStorage.getItem(state.localStorageKey)) {
        state.authentication = JSON.parse(localStorage.getItem(state.localStorageKey));
      } else {
        return '';
      }
    }
    return state.authentication.publicKey;
  },
  authentication: (state) => {
    if(IS_MOCK) {
      //return {decoded: {authority: 0, name:"admin"}}
      return {decoded: {authority: 1, name:"담당자"}}
      //return {decoded: {authority: 2, name:"admin"}}
    }
    if (!state.authentication) {
      if (localStorage.getItem(state.localStorageKey)) {
        state.authentication = JSON.parse(localStorage.getItem(state.localStorageKey));
      } else {
        return {};
      }
    }
    return state.authentication;
  },
  authenticationUserInfo: (state) => {
    if(IS_MOCK) {
      //return {authority: 0, name:"admin"}
      return {authority: 1, name:"담당자"}
      //return {authority: 2, name:"admin"}
    }
    if (!state.authentication) {
      if (localStorage.getItem(state.localStorageKey)) {
        state.authentication = JSON.parse(localStorage.getItem(state.localStorageKey));
      }
    }
    if(state.authentication && state.authentication.decoded){

      if(Object.keys(state.authentication).includes("secretUrl")) {
        alert(state.authentication.secretUrl);
      }

      //console.log("authentication:"+JSON.stringify(state.authentication));
      //console.log("login:"+JSON.stringify(state.authentication.decoded));

      //============= 220208
      if(IS_SAAS && state.authentication.decoded.passUpdateDateTime != undefined) {
        //passUpdateDateTime과 현재시간을 비교해서 날짜차를 구한다.
        //60일보다 크거나 같으면 패스워드 변경모드로 진입하고 빠져나올 수 있다.
        //90일보다 크거나 같으면 강제 패스워드 변경모드로 동작하고 메뉴를 빠져 나올 수 없다.

        let lastPassUpdateDT = moment(state.authentication.decoded.passUpdateDateTime);
        let nowDT = moment(new Date());
        let diffDays = (nowDT - lastPassUpdateDT) / (1000*3600*24);

        console.log("##### diffDays=="+diffDays);
        
        state.authentication.decoded.passForceChange = diffDays;
      } else{
        state.authentication.decoded.passForceChange = 0;
      }

      //console.log("login:"+JSON.stringify(state.authentication.decoded));

      /*
      let option = {};
      if(state.authentication.decoded.option) {
        if(typeof state.authentication.decoded.option !== 'object') { 
          //state.authentication.decoded.option = JSON.parse(state.authentication.decoded.option);
          
          if(isJson(state.authentication.decoded.option)) {
            option = JSON.parse(state.authentication.decoded.option);
          }
        }
      }
      state.authentication.decoded.option = option;
      */

      state.authentication.decoded.option = convertJson(state.authentication.decoded.option);

      return state.authentication.decoded;
    }
    else{
      //211101
      //console.log("empty");
      //return {};

      if(state.authentication == null) { return {}; }
      else { return state.authentication; }
    }
  },
  getSchoolUUID: (state) => {
    let uuid = null;
    if (!state.authentication) {
      if (localStorage.getItem(state.localStorageKey)) {
        state.authentication = JSON.parse(localStorage.getItem(state.localStorageKey));
      }
    }
    if(state.authentication && state.authentication.decoded){
      uuid = state.authentication.decoded.schoolUUID;
    }

    //if(IS_APP_DEV==false && IS_IOT_CERTI == true) {
    //  //uuid = "d42a8171-26dc-496a-b062-bb4849bdd685";
    //  uuid = "c4ac92f5-a45b-4c2b-bfbb-a4c51398de98";
    //}

    return uuid;
  },
  /**
   * @param {Object} state vuex에서 넘겨주는 state 객체
   * @param {import('../../consts/defs').Role} role
   */
  /*hasRole(state, role) {

  }*/
};

const mutations = {
  LOGIN(state, authentication) {
    try {
      console.log("LOGIN");
      //console.log("authentication="+JSON.stringify(authentication));
      let authenticationObj = fnJwtVerify(authentication);
      //console.log("authenticationObj:"+JSON.stringify(authenticationObj));
      localStorage.setItem(localStorageKey(), JSON.stringify(authenticationObj));
      sessionStorage.setItem("session" +localStorageKey() + "23", JSON.stringify(authenticationObj));
      state.authentication = authenticationObj;
    } catch (err) {
      throw err;
    }
  },
  LOGOUT(state) {
    state.authentication = null;
    localStorage.removeItem(localStorageKey());
  },
  REFRESH(state){
    if (localStorage.getItem(state.localStorageKey)) {
      state.authentication = JSON.parse(localStorage.getItem(state.localStorageKey));
    }
    else{
      state.authentication = null;
    }
  },
  setSchool(state, schoolInfo){
    state.authentication = JSON.parse(localStorage.getItem(state.localStorageKey));
    if(state.authentication){
      state.authentication.decoded.schoolUUID = schoolInfo.school.uuid;
      localStorage.setItem(localStorageKey(), JSON.stringify(state.authentication));
    }
  },
  setMarkedClassNo(state, markedClassNo) {
    state.authentication = JSON.parse(localStorage.getItem(state.localStorageKey));
    if(state.authentication) {
      state.authentication.decoded.markedClassNo = markedClassNo;
      localStorage.setItem(localStorageKey(), JSON.stringify(state.authentication));
    }
  },
  //220113
  setBasicUserInfo(state, userInfo) {
    state.authentication = JSON.parse(localStorage.getItem(state.localStorageKey));
    if(state.authentication) {
      state.authentication.decoded.name = userInfo.name;
      state.authentication.decoded.phoneNumber = userInfo.phoneNumber;
      state.authentication.decoded.extentionNumber = userInfo.extentionNumber;
      state.authentication.decoded.postNo = userInfo.postNo;
      state.authentication.decoded.address1 = userInfo.address1;
      state.authentication.decoded.address2 = userInfo.address2;
      state.authentication.decoded.option = userInfo.option;
      localStorage.setItem(localStorageKey(), JSON.stringify(state.authentication));
    }
  },
  //220208
  clearPassForceChange(state) {
    state.authentication = JSON.parse(localStorage.getItem(state.localStorageKey));
    if(state.authentication) {
      state.authentication.decoded.passForceChange = 0;
      state.authentication.decoded.passUpdateDateTime = moment(new Date());
      localStorage.setItem(localStorageKey(), JSON.stringify(state.authentication));
    }
  },
};

const actions = {
  async LOGIN({ commit }, { userId, userPwd, userOtp }) { //211101 add userOtp
    userPwd = crypto.SHA256(userPwd);
    let response = await auth.login(userId, userPwd, userOtp); //211101 add userOtp
    commit("LOGIN", response);
    // school 스토어의 getSchool 시 school uuid를 넣어주는 방법으로 변경 (2020-01-09)
    // commit("setSchool", await schoolApi.getSchool(response.decoded.schoolUUID));
  },
  async LOGOUT({ commit }) {
    let response = await auth.logout();
    commit("LOGOUT");
  },
  REFRESH({commit}) {
    commit("REFRESH");
  }
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
