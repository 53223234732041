import http from "@/api/http";
import moment from 'moment';

const PREFIX = '/school'
const IS_MOCK = process.env.VUE_APP_IS_MOCK == 'true'
const IOT_DEVICE_LOAD_SUBTRACT_MIN = parseInt(process.env.VUE_APP_IOT_DATA_LOAD_SUBTRACT_MIN) //ikkim
const IOT_DASHBOARD_LOAD_SUBTRACT_MIN = (IOT_DEVICE_LOAD_SUBTRACT_MIN+1) //ikkim
const R4470_DEVICE_LOAD_SUBTRACT_MIN = parseInt(process.env.VUE_APP_R4470_DATA_LOAD_SUBTRACT_MIN) //211008

async function getSchool(uuid) {
    let url = `${PREFIX}`
    if (!IS_MOCK) {
        return http.get(url, { uuid });
    } else {
        const mockReponse = { 
            "school": {
                "insertId": null,
                "createDateTime": "2019-11-26T21:55:50Z",
                "updateDateTime": "2019-11-26T21:55:50Z",
                "uuid": "a7c13a52-9e50-434c-b0e9-fa3b009163d5",
                "name": "돌마초등학교",
                "type": 3, //3: bus
                "post": "95000",
                "address1": "경기도 성남시 분당구",
                "address2": " 야탑동 야탑로 96-7",
                "mwgMac": "G00:11:22:33:44:55",
                "mwdsIp": "192.168.111.222",
                "enable": "Y"
            },
            "class": [
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 1,
                    "gradeName": "1",
                    "className": "시립미사호반어린이집",
                    "fullName": "시립미사호반아파트 어린이집",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [/*
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "1",
                            "position": 0,
                            "power": "Y",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "2",
                            "position": 1,
                            "power": "N",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "3",
                            "position": 1,
                            "power": "N",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "4",
                            "position": 4,
                            "power": "Y",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }*/
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "c001122334455",
                        "mwdType": "MWC",
                        "mwdOutMac" : "s001122334456",
                        "installPlace" : "사랑스런보람",
                        "enable": "Y",
                        "pm10": 12,
                        "pm25": 10,
                        "co2": 1000,
                        "voc": 1000,
                        "temp": 10,
                        "humm": 60,

                //for test
                "clockTimeHour": "12",
                "clockTimeMinute": "04",
                "clockTimeHourLevel": 1,
                "clockTimeMinuteLevel": 4

                    },
                    //for test
                    "outDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "s00112233445a",
                        "mwdType": "MWC",
                        "installPlace" : "사랑스런보람",
                        "enable": "Y",
                        "bat":1111,
                        "pm10": 12,
                        "pm25": 10,
                        "co2": 200,
                        "voc": 100,
                        "temp": 10,
                        "humm": 60
                    },
                    "option": {
                        "memos" : [{"date":"2024/12/24 12:34", "memo":"12345", "creator":"김인기"}]
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 2,
                    "gradeName": "1",
                    "className": "열린어린이집",
                    "fullName": "어린이 어린이 어린이",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [/*
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "2",
                            "position": 1,
                            "power": "N",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "3",
                            "position": 1,
                            "power": "N",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "4",
                            "position": 4,
                            "power": "Y",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }*/
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "w001122334455",
                        "mwdType": "MWW",
                        "installPlace" : "에리꾸힘내욤",
                        "enable": "Y",
                        "bat":12,
                        "pm10": 32,
                        "pm25": 1234567,
                        "co2": 2200,
                        "voc": 10,
                        "temp": 10,
                        "humm": 60
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 3,
                    "gradeName": "1",
                    "className": "3",
                    "fullName": "g743AEF83D4AB",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "N",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "1",
                            "position": 0,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "2",
                            "position": 1,
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "power": "N",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "s001122334490",
                        "mwdOutMac": "s00112233445b",
                        "mwdType": "MWS",
                        "enable": "Y",
                        "pm10": 232,
                        "pm25": 70,
                        "co2": 200,
                        "voc": 110,
                        "temp": 10,
                        "humm": 60
                    },
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 4,
                    "gradeName": "1",
                    "className": "4",
                    "fullName": "1-4",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "1",
                            "position": 0,
                            "power": "Y",
                            "irCode":"8010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        /*
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "2",
                            "position": 1,
                            "power": "N",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "3",
                            "position": 6,
                            "power": "N",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },*/
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "4",
                            "position": 8,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "i001122334491",
                        "mwdOutMac": "s001122334456",
                        "mwdType": "MWI",
                        "enable": "Y",
                        "pm10": 72,
                        "pm25": 70,
                        "co2": 2200,
                        "voc": 10,
                        "temp": 10,
                        "humm": 60
                    },
                    "option": {
                        "memos" : [{"date":"2024/12/24 12:34", "memo":"12345", "creator":"김인기222"}]
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 5,
                    "gradeName": "1",
                    "className": "6",
                    "fullName": "1-6",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "1",
                            "position": 0,
                            "power": "Y",
                            "irCode":"2010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "2",
                            "position": 3,
                            "power": "N",
                            "irCode":"3010",
                            "irOnCode":"00000000",
                            "irOffCode":"23451113",
                            "apcModelName": "R-1000",
                            "apcBrandName": "릴레이",
                            "apcType": 0,
                            "apcLevel":0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "3",
                            "position": 4,
                            "power": "N",
                            "irCode":"3010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "4",
                            "position": 4,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "5",
                            "position": 4,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "6",
                            "position": 4,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "c001122334492",
                        "mwdType": "MWC",
                        "enable": "Y",
                        "pm10": 32,
                        "pm25": 70,
                        "co2": 2200,
                        "voc": 10,
                        "temp": 10,
                        "humm": 60
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 6,
                    "gradeName": "1",
                    "className": "7",
                    "fullName": "1-7",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "1",
                            "position": 0,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "2",
                            "position": 1,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "3",
                            "position": 1,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "4",
                            "position": 5,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "c001122334493",
                        "mwdType": "MWC",
                        "enable": "Y",
                        "pm10": 32,
                        "pm25": 70,
                        "co2": 2200,
                        "voc": 10,
                        "temp": 10,
                        "humm": 60
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 7,
                    "gradeName": "2",
                    "className": "1",
                    "fullName": "2",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "1",
                            "position": 0,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "2",
                            "position": 1,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "3",
                            "position": 1,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "4",
                            "position": 4,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "c001122334494",
                        "mwdType": "MWC",
                        "enable": "Y",
                        "pm10": 32,
                        "pm25": 70,
                        "co2": 2200,
                        "voc": 10,
                        "temp": 10,
                        "humm": 60
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 8,
                    "gradeName": "2",
                    "className": "2",
                    "fullName": "2-2",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "1",
                            "position": 0,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "2",
                            "position": 1,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "3",
                            "position": 1,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "4",
                            "position": 4,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "i001122334495",
                        "mwdType": "MWI",
                        "enable": "Y",
                        "pm10": 32,
                        "pm25": 70,
                        "co2": 2200,
                        "voc": 10,
                        "temp": 10,
                        "humm": 60
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 9,
                    "gradeName": "2",
                    "className": "3",
                    "fullName": "2-3",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "1",
                            "position": 0,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "2",
                            "position": 1,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "3",
                            "position": 1,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "4",
                            "position": 4,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "i001122334496",
                        "mwdType": "MWI",
                        "enable": "Y",
                        "pm10": 32,
                        "pm25": 70,
                        "co2": 2200,
                        "voc": 10,
                        "temp": 10,
                        "humm": 60
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 10,
                    "gradeName": "2",
                    "className": "4",
                    "fullName": "2-4",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "1",
                            "position": 3,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "2",
                            "position": 3,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "3",
                            "position": 1,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "4",
                            "position": 4,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "i001122334497",
                        "mwdType": "MWI",
                        "enable": "Y",
                        "pm10": 102,
                        "pm25": 170,
                        "co2": 200,
                        "voc": 120,
                        "temp": 10,
                        "humm": 60
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 11,
                    "gradeName": "3",
                    "className": "1",
                    "fullName": "3-1",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "1",
                            "position": 0,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "2",
                            "position": 1,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "3",
                            "position": 1,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "4",
                            "position": 4,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "i001122334498",
                        "mwdType": "MWI",
                        "enable": "Y",
                        "pm10": 32,
                        "pm25": 70,
                        "co2": 2200,
                        "voc": 10,
                        "temp": 10,
                        "humm": 60
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 12,
                    "gradeName": "3",
                    "className": "2",
                    "fullName": "3-2",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "1",
                            "position": 0,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "2",
                            "position": 1,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "3",
                            "position": 1,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "4",
                            "position": 4,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "i001122334499",
                        "mwdType": "MWI",
                        "enable": "Y",
                        "pm10": 32,
                        "pm25": 70,
                        "co2": 2200,
                        "voc": 10,
                        "temp": 10,
                        "humm": 60
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 13,
                    "gradeName": "3",
                    "className": "3",
                    "fullName": "3-3",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "5",
                            "position": 2,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "6",
                            "position": 3,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "7",
                            "position": 4,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "8",
                            "position": 4,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "G22:22:22:22:22:22",
                        "mwdType": "MWI",
                        "enable": "N",
                        "pm10": 12,
                        "pm25": 30,
                        "co2": 900,
                        "voc": 300,
                        "temp": 15,
                        "humm": 39
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 14,
                    "gradeName": "3",
                    "className": "3",
                    "fullName": "3-3",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "5",
                            "position": 2,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "6",
                            "position": 3,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "7",
                            "position": 4,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "8",
                            "position": 4,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "G22:22:22:22:22:22",
                        "mwdType": "MWI",
                        "enable": "N",
                        "pm10": 12,
                        "pm25": 30,
                        "co2": 900,
                        "voc": 300,
                        "temp": 15,
                        "humm": 39
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 15,
                    "gradeName": "3",
                    "className": "3",
                    "fullName": "3-3",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "5",
                            "position": 2,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "6",
                            "position": 3,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "7",
                            "position": 4,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "8",
                            "position": 4,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "G22:22:22:22:22:22",
                        "mwdType": "MWI",
                        "enable": "N",
                        "pm10": 12,
                        "pm25": 30,
                        "co2": 900,
                        "voc": 300,
                        "temp": 15,
                        "humm": 39
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 16,
                    "gradeName": "3",
                    "className": "3",
                    "fullName": "3-3",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "5",
                            "position": 2,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "6",
                            "position": 3,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "7",
                            "position": 4,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "8",
                            "position": 4,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "G22:22:22:22:22:22",
                        "mwdType": "MWI",
                        "enable": "N",
                        "pm10": 12,
                        "pm25": 30,
                        "co2": 900,
                        "voc": 300,
                        "temp": 15,
                        "humm": 39
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 17,
                    "gradeName": "3",
                    "className": "3",
                    "fullName": "3-3",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "5",
                            "position": 2,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "6",
                            "position": 3,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "7",
                            "position": 4,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "8",
                            "position": 4,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "G22:22:22:22:22:22",
                        "mwdType": "MWI",
                        "enable": "N",
                        "pm10": 12,
                        "pm25": 30,
                        "co2": 900,
                        "voc": 300,
                        "temp": 15,
                        "humm": 39
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 18,
                    "gradeName": "3",
                    "className": "3",
                    "fullName": "3-3",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "Y",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "5",
                            "position": 2,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "6",
                            "position": 3,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "7",
                            "position": 4,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "8",
                            "position": 4,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "G22:22:22:22:22:22",
                        "mwdType": "MWI",
                        "enable": "N",
                        "pm10": 12,
                        "pm25": 30,
                        "co2": 900,
                        "voc": 300,
                        "temp": 15,
                        "humm": 39
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 19,
                    "gradeName": "10",
                    "className": "어린이집",
                    "fullName": "10-1",
                    "enable": "Y",
                    "classType": 0,
                    "connectCount" : 99,
                    "isConnected": "N",
                    "apcInfo": [
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:14Z",
                            "updateDateTime": "2019-11-27T04:20:14Z",
                            "apcInfoNo": "5",
                            "position": 2,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:20:57Z",
                            "updateDateTime": "2019-11-27T04:20:57Z",
                            "apcInfoNo": "6",
                            "position": 3,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "퓨리케어",
                            "apcBrandName": "LG전자",
                            "apcType": 0
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:21:43Z",
                            "updateDateTime": "2019-11-27T04:21:43Z",
                            "apcInfoNo": "7",
                            "position": 4,
                            "power": "Y",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        },
                        {
                            "insertId": null,
                            "createDateTime": "2019-11-27T04:22:04Z",
                            "updateDateTime": "2019-11-27T04:22:04Z",
                            "apcInfoNo": "8",
                            "position": 4,
                            "power": "N",
                            "irCode":"A010",
                            "irOnCode":"0000",
                            "irOffCode":"0000",
                            "apcModelName": "에어 서큘레이터",
                            "apcBrandName": "다이슨",
                            "apcType": 1
                        }
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "G22:22:22:22:22:22",
                        "mwdType": "MWI",
                        "enable": "N",
                        "pm10": 12,
                        "pm25": 30,
                        "co2": 900,
                        "voc": 300,
                        "temp": 15,
                        "humm": 39
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 20,
                    "gradeName": "기타",
                    "className": "어린이집2",
                    "fullName": "운동장2",
                    "enable": "Y",
                    "classType": 1,
                    "isConnected": "Y", //for test
                    "apcInfo": [
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "s00112233445a",
                        "mwdType": "MWS",
                        "enable": "Y",
                        "pm10": 100,
                        "pm25": 30,
                        "co2": 300,
                        "voc": 500,
                        "temp": 25,
                        "humm": 20
                    }
                },
                {
                    "insertId": null,
                    "createDateTime": "2019-11-27T03:44:05Z",
                    "updateDateTime": "2019-11-27T03:44:05Z",
                    "classNo": 21,
                    "gradeName": "기타",
                    "className": "어린이집2",
                    "fullName": "운동장",
                    "enable": "Y",
                    "classType": 1,
                    "isConnected": "N", //for test
                    "apcInfo": [
                    ],
                    "mwdDevice": {
                        "insertId": null,
                        "createDateTime": "2019-11-28T21:19:20Z",
                        "updateDateTime": "2019-11-28T21:19:20Z",
                        "mwdMac": "s001122334456",
                        "mwdType": "MWS",
                        "enable": "Y",
                        "pm10": 10,
                        "pm25": 16,
                        "co2": 0,
                        "voc": 0,
                        "temp": 25,
                        "humm": 20
                    }
                }
            ]
        }

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function getSchoolInfoByNo(schoolNo) {
    let url = `${PREFIX}/${schoolNo}`
    if (!IS_MOCK) {
        return http.get(url);
    } else {
        const mockReponse = { 
            
        }

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function getDeviceInfo(uuid, wDevice, sDevice, group = null, dateTime = null, limit = 0){
    const url = '/iot/device';
    if (!IS_MOCK) {
        if(dateTime === null){
            // for server
            //alert(moment(new Date()).subtract('minutes', IOT_DEVICE_LOAD_SUBTRACT_MIN/*5*//*1*/));
            //210923 dateTime = moment(new Date()).subtract('minutes', IOT_DEVICE_LOAD_SUBTRACT_MIN/*5*//*1*/).seconds(0).milliseconds(0).toISOString(); // 데이터부터 로딩 ikkim
            dateTime = moment(new Date()).subtract(IOT_DEVICE_LOAD_SUBTRACT_MIN, 'm' /*5*//*1*/).seconds(0).milliseconds(0).toISOString(); // 데이터부터 로딩 ikkim

            //for test
            //dateTime = '2019-11-14 04:38:00';
            //limit = 10;

        }

        //console.log("[getDeviceInfo-1] "+JSON.stringify(wDevice)+"  "+JSON.stringify(sDevice));

        //211008 support 무선 검침기
        let dateTimeR4470 = null;
        if(wDevice.length != 0 && R4470_DEVICE_LOAD_SUBTRACT_MIN != 0) {
            dateTimeR4470 = moment(new Date()).subtract(R4470_DEVICE_LOAD_SUBTRACT_MIN, 'm').seconds(0).milliseconds(0).toISOString(); // 데이터부터 로딩 ikkim
        }

        if(sDevice.length > 0) {
            sDevice = JSON.stringify(sDevice);
        } else {
            sDevice = null;
        }    
        //console.log("[getDeviceInfo-2] "+sDevice);
        return http.get(url, { uuid, group, dateTime, dateTimeR4470, sDevice, limit });
    } else {
        const mockReponse = {
            "m2m:rsp": {
                "m2m:cin": [
                    {
                        "pi": "3gplM3RGQQ",
                        "ri": "Y_9GBjGusG",
                        "ty": 4,
                        "ct": "20191114T043826",
                        "st": 46495,
                        "rn": "4-20191114043826107747175",
                        "lt": "20191114T043826",
                        "et": "20221114T043826",
                        "lbl": [
                            66,
                            25,
                            23,
                            1,
                            1,
                            1049,
                            2719
                        ],
                        "cs": 18,
                        "cr": "Smisewatch",
                        "con": "G11:11:11:11:11:11"
                    },
                    {
                        "pi": "3gplM3RGQQ",
                        "ri": "b6tE7Zn3Jp",
                        "ty": 4,
                        "ct": "20191114T043826",
                        "st": 46495,
                        "rn": "4-20191114043826113878419",
                        "lt": "20191114T043826",
                        "et": "20221114T043826",
                        "lbl": [
                            66,
                            23,
                            26,
                            2,
                            2,
                            430,
                            4914
                        ],
                        "cs": 18,
                        "cr": "Smisewatch",
                        "con": "G22:22:22:22:22:22"
                    },
                    {
                        "pi": "3gplM3RGQQ",
                        "ri": "4f9enrQFiN",
                        "ty": 4,
                        "ct": "20191114T043826",
                        "st": 46495,
                        "rn": "4-20191114043826116169957",
                        "lt": "20191114T043826",
                        "et": "20221114T043826",
                        "lbl": [
                            66,
                            27,
                            20,
                            1,
                            1,
                            494,
                            652
                        ],
                        "cs": 18,
                        "cr": "Smisewatch",
                        "con": "G33:33:33:33:33:33"
                    }
                ]
            }
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        });
    }

}

async function getDeviceInfoLatest(uuid, device){
    const url = '/iot/device/latest';
    if (!IS_MOCK) {
        //console.log("[getDeviceInfoLatest] uuid="+uuid+" devices="+JSON.stringify(device));

        /*
        if(device.length > 0) {
            device = JSON.stringify(device);
        } else {
            device = null;
        } 
        */

        //console.log("[getDeviceInfo-2] "+sDevice);
        return http.get(url, { uuid, device });
    } else {
        const mockReponse = {
            "m2m:rsp": {
                "m2m:cin": [
                    {
                        "pi": "3gplM3RGQQ",
                        "ri": "Y_9GBjGusG",
                        "ty": 4,
                        "ct": "20191114T043826",
                        "st": 46495,
                        "rn": "4-20191114043826107747175",
                        "lt": "20191114T043826",
                        "et": "20221114T043826",
                        "lbl": [
                            66,
                            25,
                            23,
                            1,
                            1,
                            1049,
                            2719
                        ],
                        "cs": 18,
                        "cr": "Smisewatch",
                        "con": "G11:11:11:11:11:11"
                    },
                    {
                        "pi": "3gplM3RGQQ",
                        "ri": "b6tE7Zn3Jp",
                        "ty": 4,
                        "ct": "20191114T043826",
                        "st": 46495,
                        "rn": "4-20191114043826113878419",
                        "lt": "20191114T043826",
                        "et": "20221114T043826",
                        "lbl": [
                            66,
                            23,
                            26,
                            2,
                            2,
                            430,
                            4914
                        ],
                        "cs": 18,
                        "cr": "Smisewatch",
                        "con": "G22:22:22:22:22:22"
                    },
                    {
                        "pi": "3gplM3RGQQ",
                        "ri": "4f9enrQFiN",
                        "ty": 4,
                        "ct": "20191114T043826",
                        "st": 46495,
                        "rn": "4-20191114043826116169957",
                        "lt": "20191114T043826",
                        "et": "20221114T043826",
                        "lbl": [
                            66,
                            27,
                            20,
                            1,
                            1,
                            494,
                            652
                        ],
                        "cs": 18,
                        "cr": "Smisewatch",
                        "con": "G33:33:33:33:33:33"
                    }
                ]
            }
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        });
    }

}

async function getDashboardInfo(uuid, wDevice, sDevice){
    const url = '/iot/dashboard';
    /*
    if (!IS_MOCK) {
        if(dateTime === null){
            // for server
            dateTime = moment(new Date()).toISOString();

            //for test
            //dateTime = '2019-11-14 04:38:00';
            //limit = 10;
        }
    }
    */
    // for server
    // 1분 전 데이터 조회
    // 최초 데이터는 Sync가 맞지 않을 수 있으므로, 2분 전으로 전송
    
    // mock data 분기 추가
    if(!IS_MOCK) {
        //alert(moment(new Date()));
        //alert("iot2="+IOT_DASHBOARD_LOAD_SUBTRACT_MIN);
        //210923 let dateTime = moment(new Date()).subtract('m', IOT_DASHBOARD_LOAD_SUBTRACT_MIN/*6*//*2*/).seconds(0).milliseconds(0).toISOString(); // 데이터부터 로딩 ikkim
        let dateTime = moment(new Date()).subtract(IOT_DASHBOARD_LOAD_SUBTRACT_MIN, 'm' /*6*//*2*/).seconds(0).milliseconds(0).toISOString(); // 데이터부터 로딩 ikkim
        
        //console.log("[getDashboardInfo-1] "+JSON.stringify(wDevice)+"  "+JSON.stringify(sDevice));

        //211008 support 무선 검침기
        let dateTimeR4470 = null;
        if(wDevice.length != 0 && R4470_DEVICE_LOAD_SUBTRACT_MIN != 0) {
            dateTimeR4470 = moment(new Date()).subtract(R4470_DEVICE_LOAD_SUBTRACT_MIN, 'm' /*6*//*2*/).seconds(0).milliseconds(0).toISOString(); // 데이터부터 로딩 ikkim
        }
        //console.log("[getDashboardInfo-1] "+JSON.stringify(sDevice));
        if(sDevice.length > 0) {
            sDevice = JSON.stringify(sDevice); //sDevice.toString();//
        } else {
            sDevice = null;
        }
        //console.log("[getDashboardInfo-2] "+JSON.stringify(sDevice));

        return http.get(url, { uuid, dateTime, dateTimeR4470, sDevice });
    } else {
        const mockReponse = {
            apc: null,
            device: null,
            station: null
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        });
    }

    //let dateTime = moment('20191114T000000').subtract('m', 1).toISOString();
}

async function getSchoolList(searchValue = null, enable = null, isExistGateway = null, page = null){
    let url = `${PREFIX}/list`
    if (!IS_MOCK) {
        let ver = new Date().getTime();
        return http.get(url, { ver, searchValue, enable, isExistGateway,...page });
    } else {
        const mockReponse = {
            "totalcount":98,
            "list":[
                {"insertId":null,"createDateTime":"2020-01-19T22:20:38Z","updateDateTime":"2020-01-20T06:58:58Z","schoolNo":4,"uuid":"c8a10db7-bd49-4ad2-bda9-e8d2623b99b8","name":"용인초등학교","type":0,"post":"17044","address1":"경기 용인시 처인구 금학로367번길 14","address2":"용인초등학교","mwdsIp":null,"enable":"Y","installationNum":"942062","telNo":"123-12333-123","bizNo":"1234","rank":0,"deviceCount":0,"schoolClass":null},
                {"insertId":null,"createDateTime":"2019-11-27T14:55:52Z","updateDateTime":"2019-11-27T14:55:50Z","schoolNo":3,"uuid":"a7c13a52-9e50-434c-b0e9-fa3b009163d8","name":"이세돌돌초등학교","type":0,"post":"95000","address1":"경기도 성남시 분당구","address2":" 야탑동 야탑로 96-7","mwdsIp":"192.168.111.222","enable":"Y","installationNum":null,"telNo":null,"bizNo":null,"rank":0,"deviceCount":0,"schoolClass":null},
                {"insertId":null,"createDateTime":"2019-11-27T14:55:51Z","updateDateTime":"2019-11-27T14:55:50Z","schoolNo":2,"uuid":"a7c13a52-9e50-434c-b0e9-fa3b009163d7","name":"이세돌마초등학교","type":0,"post":"95000","address1":"경기도 성남시 분당구","address2":" 야탑동 야탑로 96-7","mwdsIp":"192.168.111.222","enable":"Y","installationNum":null,"telNo":null,"bizNo":null,"rank":0,"deviceCount":0,"schoolClass":null},
                {"insertId":null,"createDateTime":"2019-11-27T14:55:50Z","updateDateTime":"2020-01-17T03:54:51Z","schoolNo":1,"uuid":"a7c13a52-9e50-434c-b0e9-fa3b009163d6","name":"돌마초등학교","type":0,"post":"13517","address1":"경기 성남시 분당구 야탑로 96-7","address2":"돌마초등학교","mwdsIp":"192.168.111.222","enable":"Y","installationNum":"979001","telNo":"031-1234-1234","bizNo":"100","rank":0,"deviceCount":5,"schoolClass":null},
                
                {"insertId":null,"createDateTime":"2019-11-27T14:55:51Z","updateDateTime":"2019-11-27T14:55:50Z","schoolNo":2,"uuid":"a7c13a52-9e50-434c-b0e9-fa3b009163d7","name":"A중학교","type":0,"post":"95000","address1":"경기도 성남시 분당구","address2":" 야탑동 야탑로 96-7","mwdsIp":"192.168.111.222","enable":"Y","installationNum":null,"telNo":null,"bizNo":null,"rank":0,"deviceCount":0,"schoolClass":null},
                {"insertId":null,"createDateTime":"2019-11-27T14:55:51Z","updateDateTime":"2019-11-27T14:55:50Z","schoolNo":2,"uuid":"a7c13a52-9e50-434c-b0e9-fa3b009163d7","name":"b초등학교","type":0,"post":"95000","address1":"경기도 성남시 분당구","address2":" 야탑동 야탑로 96-7","mwdsIp":"192.168.111.222","enable":"Y","installationNum":null,"telNo":null,"bizNo":null,"rank":0,"deviceCount":0,"schoolClass":null},
                {"insertId":null,"createDateTime":"2019-11-27T14:55:51Z","updateDateTime":"2019-11-27T14:55:50Z","schoolNo":2,"uuid":"a7c13a52-9e50-434c-b0e9-fa3b009163d7","name":"♥초등학교","type":0,"post":"95000","address1":"경기도 성남시 분당구","address2":" 야탑동 야탑로 96-7","mwdsIp":"192.168.111.222","enable":"Y","installationNum":null,"telNo":null,"bizNo":null,"rank":0,"deviceCount":0,"schoolClass":null},
                {"insertId":null,"createDateTime":"2019-11-27T14:55:51Z","updateDateTime":"2019-11-27T14:55:50Z","schoolNo":2,"uuid":"a7c13a52-9e50-434c-b0e9-fa3b009163d7","name":"●초등학교","type":0,"post":"95000","address1":"경기도 성남시 분당구","address2":" 야탑동 야탑로 96-7","mwdsIp":"192.168.111.222","enable":"Y","installationNum":null,"telNo":null,"bizNo":null,"rank":0,"deviceCount":0,"schoolClass":null},
                {"insertId":null,"createDateTime":"2019-11-27T14:55:51Z","updateDateTime":"2019-11-27T14:55:50Z","schoolNo":2,"uuid":"a7c13a52-9e50-434c-b0e9-fa3b009163d7","name":"상초등학교","type":0,"post":"95000","address1":"경기도 성남시 분당구","address2":" 야탑동 야탑로 96-7","mwdsIp":"192.168.111.222","enable":"Y","installationNum":null,"telNo":null,"bizNo":null,"rank":0,"deviceCount":0,"schoolClass":null},
                {"insertId":null,"createDateTime":"2019-11-27T14:55:51Z","updateDateTime":"2019-11-27T14:55:50Z","schoolNo":2,"uuid":"a7c13a52-9e50-434c-b0e9-fa3b009163d7","name":"쌍초등학교","type":0,"post":"95000","address1":"경기도 성남시 분당구","address2":" 야탑동 야탑로 96-7","mwdsIp":"192.168.111.222","enable":"Y","installationNum":null,"telNo":null,"bizNo":null,"rank":0,"deviceCount":0,"schoolClass":null},
                {"insertId":null,"createDateTime":"2019-11-27T14:55:51Z","updateDateTime":"2019-11-27T14:55:50Z","schoolNo":2,"uuid":"a7c13a52-9e50-434c-b0e9-fa3b009163d7","name":"빵초등학교","type":0,"post":"95000","address1":"경기도 성남시 분당구","address2":" 야탑동 야탑로 96-7","mwdsIp":"192.168.111.222","enable":"Y","installationNum":null,"telNo":null,"bizNo":null,"rank":0,"deviceCount":0,"schoolClass":null},
            ]
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        });
    }

}

async function createSchool(param){
    let url = `${PREFIX}`
    return http.post(url, param, {headers : {
        'Content-type' : 'multipart/form-data'
      }});
}
async function updateSchool(param){
    let url = `${PREFIX}/update`
    //return http.put(url, param);
    return http.post(url, param, {headers : {
        'Content-type' : 'multipart/form-data'
      }});
}
async function updateSchoolClass(param){
    if (!IS_MOCK) {
        let url = `${PREFIX}/updateclass`
        return http.put(url, param);
    } else {
        const mockReponse = { 
            
        }

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function updateSchoolByManager(param){
    let url = `${PREFIX}/updatebymanager`
    //return http.put(url, param);
    return http.post(url, param, {headers : {
        'Content-type' : 'multipart/form-data'
      }});
}

async function updateSchoolOption(param){
    let url = `${PREFIX}/updateoption`
    return http.put(url, param);
}

async function deleteSchool(uuid){
    //let url = `${PREFIX}`
    //return http.delete(url, uuid);
    
    //220428
    let url = `${PREFIX}/${uuid}`
    return http.delete(url);
}

export default {
    getSchool,
    getSchoolInfoByNo,
    getDeviceInfo,
    getDeviceInfoLatest,
    getDashboardInfo,
    getSchoolList,
    createSchool,
    updateSchool,
    updateSchoolClass,
    updateSchoolByManager,
    updateSchoolOption,
    deleteSchool
};
